<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <img src="@/assets/images/logo/logo.png" width="70" alt="Informes SBG"/>
          Informes SBG
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido a Informes SBG
          </b-card-title>
          <b-card-text class="mb-2">
            Validando acceso a aplicación
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {db, firebase} from '@/firebaseConfig';
import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg
} from 'bootstrap-vue';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import localForage from 'localforage';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg
  },
  data() {
    return {
      loading: null,
      token: '',
      loaded: false,
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/login-v2.svg')
    };
  },
  computed: {
    config() {
      return store.state.fastway.parameters;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route, 'params')) {
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'token')) {
        this.token = this.$route.params.token;
        if (this.token != '') {
          this.loading = this.$loading.show();
          this.validateToken(this.token);
        } else {
          this.$router.push({name: 'login'});
        }
      } else {
        this.$router.push({name: 'login'});
      }
    } else {
      this.$router.push({name: 'login'});
    }
  },
  methods: {
    validateToken(token) {
      const self = this;
      this.$http.post(`${self.config.apiURL}/users/validate_token`, {token})
        .then(response => {
          if (response.data.success) {
            if (response.data.data.token == '') {
              self.loading.hide();
              self.$router.push({name: 'login'});
            } else {
              const userInfo = {
                uid: response.data.data.id,
                userId: response.data.data.id,
                login: response.data.data.login,
                userName: response.data.data.login,
                displayName: response.data.data.name,
                branchId: response.data.data.branchId,
                branchName: response.data.data.branchName,
                token: response.data.data.token
              };
              db.collection('sessions')
                .doc(userInfo.token)
                .set({
                  token: userInfo.token,
                  userId: userInfo.uid,
                  loginDate: firebase.firestore.FieldValue.serverTimestamp(),
                  lastUpdate: firebase.firestore.FieldValue.serverTimestamp(),
                  active: true
                })
                .then(() => {
                  if (response.data.data.image != "") {
                    userInfo.photoURL = response.data.data.image;
                  }
                  const {prefix} = self.config;
                  localForage.setItem(`${prefix}UserInfo`, userInfo);
                  localForage.setItem(`${prefix}UserId`, response.data.data.id);
                  localForage.setItem(`${prefix}Login`, response.data.data.login);
                  localForage.setItem(`${prefix}UserName`, response.data.data.name);
                  localForage.setItem(`${prefix}Token`, response.data.data.token);
                  self.$store.dispatch('fastway/updateUserInfo', userInfo);
                  store.dispatch('fastway/bindSession', userInfo.token);
                  self.$http
                    .post(`${self.config.apiURL}/users/options`, {
                      userId: response.data.data.id,
                      token: response.data.data.token
                    })
                    .then(optionsResponse => {
                      if (optionsResponse.data.success) {
                        if (optionsResponse.data.data) {
                          self.$store.dispatch('fastway/updateNavMenuItems', self.getMenu(optionsResponse.data.data));
                        }
                        window.location.assign('/');
                      } else {
                        self.loading.hide();
                        self.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Error al validar opciones de usuario',
                            text: optionsResponse.data.message,
                            icon: 'AlertCircleIcon',
                            variant: 'danger'
                          }
                        });
                      }
                    })
                    .catch(error => {
                      self.loading.hide();
                      let errorMessage = '';
                      if (error.message) {
                        errorMessage = error.message;
                      } else if (error.response) {
                        errorMessage = error.response.data.ErrorMessage;
                      } else {
                        errorMessage = 'Error de conectividad';
                      }
                      self.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Error al validar credenciales',
                          text: errorMessage,
                          icon: 'AlertCircleIcon',
                          variant: 'danger'
                        }
                      });
                    });
                });
            }
          }
        })
        .catch(error => {
          self.loading.hide();
          let errorMessage = '';
          if (error.message) {
            errorMessage = error.message;
          } else if (error.response) {
            errorMessage = error.response.data.ErrorMessage;
          } else {
            errorMessage = 'Error de conectividad';
          }
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al validar credenciales',
              text: errorMessage,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          });
        });
    },
    getMenu(menu) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].options) {
          if (menu[i].options.length > 0) {
            menu[i].submenu = this.getMenu(menu[i].options);
          }
        }
      }
      return menu;
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
