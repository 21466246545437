<template>
  <transition name="fade">
    <div>
      <ejs-tab ref="tabs">
        <div class="e-tab-header">
          <div>Venta por mes</div>
          <div>Venta por semana</div>
        </div>
        <div class="e-content">
          <div>
            <b-card title="Venta por mes - Biblias" class="mb-1">
              <b-container>
                <vue-apex-charts type="line" height="320" :options="chart1.chartOptions" :series="chart1.series"></vue-apex-charts>
              </b-container>
            </b-card>
            <b-card title="Venta por mes - Otros productos">
              <b-container>
                <vue-apex-charts type="line" height="320" :options="chart2.chartOptions" :series="chart2.series"></vue-apex-charts>
              </b-container>
            </b-card>
          </div>
          <div>
            <b-card title="Venta por semana - Biblias" class="mb-1">
              <b-container>
                <vue-apex-charts type="line" height="320" :options="chart3.chartOptions" :series="chart3.series"></vue-apex-charts>
              </b-container>
            </b-card>
            <b-card title="Venta por semana - Otros productos">
              <b-container>
                <vue-apex-charts type="line" height="320" :options="chart4.chartOptions" :series="chart4.series"></vue-apex-charts>
              </b-container>
            </b-card>
          </div>
        </div>
      </ejs-tab>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import {BCard, BContainer} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import {TabPlugin} from '@syncfusion/ej2-vue-navigations';
import VueApexCharts from 'vue-apexcharts';

Vue.use(TabPlugin);

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    VueApexCharts
  },
  data() {
    return {
      chart1: {
        series: [],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E', '#0000FF'],
          xaxis: {
            categories: [...Array(13).keys()].slice(1, 13),
          }
        }
      },
      chart2: {
        series: [],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E', '#0000FF'],
          xaxis: {
            categories: [...Array(13).keys()].slice(1, 13),
          }
        }
      },
      chart3: {
        series: [],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E', '#0000FF'],
          xaxis: {
            categories: [...Array(53).keys()].slice(1, 53),
          }
        }
      },
      chart4: {
        series: [],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          colors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E', '#0000FF'],
          xaxis: {
            categories: [...Array(53).keys()].slice(1, 53),
          }
        }
      }
    }
  },
  computed: {
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  mounted() {
    this.$store.dispatch('fastway/updateNavMenuItems', []);
    if (this.$store.state.fastway.userInfo.token != '') {
      const self = this;
      this.$http
        .post(`${this.$store.state.fastway.parameters.apiURL}/users/options`, {
          userId: this.$store.state.fastway.userInfo.userId,
          token: this.$store.state.fastway.userInfo.token,
          module: 'EST'
        })
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              const menu = self.getMenu(response.data.data);
              self.$store.dispatch('fastway/updateNavMenuItems', menu);
              self.$forceUpdate();
            }
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al validar opciones de usuario',
                text: response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            });
          }
          self.getDashboard();
        })
        .catch(error => {
          let errorMessage = '';
          if (error.message) {
            errorMessage = error.message;
          } else if (error.response) {
            errorMessage = error.response.data.ErrorMessage;
          } else {
            errorMessage = 'Error de conectividad';
          }
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al validar credenciales',
              text: errorMessage,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          });
        });
    }
  },
  methods: {
    getMenu(menu) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].children) {
          if (menu[i].children.length > 0) {
            menu[i].submenu = this.getMenu(menu[i].children);
          }
        }
      }
      return menu;
    },
    getDashboard() {
      const loading = this.$loading.show();
      const self = this;
      let data;
      let i = 0;
      let w = 0;
      let json;
      this.$http
        .post(`${this.api}/v1/dashboards/monthly_sales`, {category: 'BIB'})
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              self.chart1.series = [];
              for (i = 0; i < response.data.data.length; i++) {
                data = [];
                json = JSON.parse(response.data.data[i].months);
                for (w = 1; w < 13; w++) {
                  if (w in json) {
                    data.push(json[w]);
                  } else {
                    data.push(0);
                  }
                }
                self.chart1.series.push({
                  name: response.data.data[i].year,
                  data
                });
              }
            }
          }
          return self.$http.post(`${this.api}/v1/dashboards/monthly_sales`, {category: ''});
        })
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              self.chart2.series = [];
              for (i = 0; i < response.data.data.length; i++) {
                data = [];
                json = JSON.parse(response.data.data[i].months);
                for (w = 1; w < 13; w++) {
                  if (w in json) {
                    data.push(json[w]);
                  } else {
                    data.push(0);
                  }
                }
                self.chart2.series.push({
                  name: response.data.data[i].year,
                  data
                });
              }
            }
          }
          return self.$http.post(`${this.api}/v1/dashboards/weekly_sales`, {category: 'BIB'});
        })
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              self.chart3.series = [];
              for (i = 0; i < response.data.data.length; i++) {
                data = [];
                json = JSON.parse(response.data.data[i].weeks);
                for (w = 1; w < 53; w++) {
                  if (w in json) {
                    data.push(json[w]);
                  } else {
                    data.push(0);
                  }
                }
                self.chart3.series.push({
                  name: response.data.data[i].year,
                  data
                });
              }
            }
          }
          return self.$http.post(`${this.api}/v1/dashboards/weekly_sales`, {category: ''});
        })
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              self.chart4.series = [];
              for (i = 0; i < response.data.data.length; i++) {
                data = [];
                json = JSON.parse(response.data.data[i].weeks);
                for (w = 1; w < 53; w++) {
                  if (w in json) {
                    data.push(json[w]);
                  } else {
                    data.push(0);
                  }
                }
                self.chart4.series.push({
                  name: response.data.data[i].year,
                  data
                });
              }
            }
          }
          loading.hide();
        })
        .catch(error => {
          let errorMessage = '';
          if (error.message) {
            errorMessage = error.message;
          } else if (error.response) {
            errorMessage = error.response.data.ErrorMessage;
          } else {
            errorMessage = 'Error de conectividad'
          }
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar estadísticas',
              text: errorMessage,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
          loading.hide();
        });
    }
  }
};
</script>

<style lang="scss">
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
</style>
