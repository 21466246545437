<!-- =========================================================================================
    File Name: StockValuation.vue
    Description: Stock valuation with accounting comparison
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 270px;">
            <ejs-datepicker ref="date" v-model="date" floatLabelType="Auto" placeholder="Calcular al día"/>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getData">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <ejs-grid
          id="grid"
          ref="grid"
          :dataSource="data"
          locale='es-GT'
          :toolbar='toolbarOptions'
          :allowPaging="true"
          :pageSettings='pageSettings'
          :allowSorting='true'
          :sortSettings='sortOptions'
          :allowExcelExport='true'
          :allowGrouping='false'
          :toolbarClick='toolbarClick'
          :allowFiltering='true'
          :filterSettings='filterOptions'
        >
          <e-columns>
            <e-column field='barcode' headerText='ISBN' width="170"/>
            <e-column field='code' headerText='Código' width="200"/>
            <e-column field='name' headerText='Producto' width="500" clipMode='EllipsisWithTooltip'/>
            <e-column field='category' headerText='Categoría' width="140" clipMode='EllipsisWithTooltip'/>
            <e-column field='quantity' headerText='Cantidad' type="number" textAlign='Right' format="N0" width="130" />
            <e-column field='cost' headerText='Costo unitario' type="number" textAlign='Right' format="N2" width="180" />
            <e-column field='total' headerText='Costo total' type="number" textAlign='Right' format="N2" width="180" />
            <e-column field='accounting_value' headerText='Valor contable' type="number" textAlign='Right' format="N2" width="180" />
            <e-column field='difference_accounting' headerText='Diferencia contabilidad' type="number" textAlign='Right' format="N2" width="210" />
            <e-column field='odoo_quantity' headerText='Cantidad Odoo' type="number" textAlign='Right' format="N0" width="170" />
            <e-column field='odoo_cost' headerText='Valor Odoo' type="number" textAlign='Right' format="N2" width="180" />
            <e-column field='difference_odoo_quantity' headerText='Diferencia cantidad Odoo' type="number" textAlign='Right' format="N0" width="220" />
            <e-column field='difference_odoo_cost' headerText='Diferencia valor Odoo' type="number" textAlign='Right' format="N2" width="200" />
          </e-columns>
        </ejs-grid>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {Query} from "@syncfusion/ej2-data";
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import {NumericTextBoxPlugin} from "@syncfusion/ej2-vue-inputs";
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

Vue.use(GridPlugin, AutoComplete);
Vue.use(DatePickerPlugin);
Vue.use(NumericTextBoxPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datepicker: {
      placeholder: 'Seleccione fecha a calcular',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      today: 'Hoy',
      now: 'Hoy'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      date: new Date(),
      months: 12,
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 25},
      sortOptions: {
        columns: [{
          field: 'barcode',
          direction: 'Ascending'
        }, {
          field: 'code',
          direction: 'Ascending'
        }, {
          field: 'name',
          direction: 'Ascending'
        }]
      },
      locationId: null,
      locationQuery: new Query().select(['name', 'id']).take(10),
      locations: [],
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Group, Filter],
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  mounted() {
    const self = this;
    this.$http
      .post(`${this.api}/v1/catalogs/locations`)
      .then(response => {
        if (response.data.success) {
          self.locations = response.data.data;
        } else {
          this.$vs.notify({
            title: 'Error al cargar bodegas',
            text: response.data.message,
            color: 'danger'
          });
        }
      });
  },
  methods: {
    locationFiltering (e) {
      const searchData = JSON.parse(JSON.stringify(this.locations));
      if (e.text == '') {
        e.updateData(searchData);
      } else {
        let query = new Query().select(['name', 'id']);
        query = (e.text !== '') ? query.where('name', 'contains', e.text, true, true) : query;
        e.updateData(searchData, query);
      }
    },
    formatDate (field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    toolbarClick (args) {
      if (args.item.id === 'grid_excelexport') {
        let location = ' - Todas las bodegas';
        if (this.locationId) {
          const locationId = this.locations.filter(item => item.id === this.locationId);
          if (locationId.length > 0) {
            location = ` - ${locationId[0].name}`
          }
        }
        this.$refs.grid.excelExport({fileName: `Inventario valuado al ${this.date.toLocaleDateString('es-GT', {year: 'numeric', month: '2-digit', day: '2-digit'}).replace(/\//g, '-')}${location}.xlsx`});
      }
    },
    getData() {
      if (this.date) {
        const loading = this.$loading.show();
        const self = this;
        this.data = [];
        // const thisapi = 'http://localhost:8080'
        this.$http.post(`${this.api}/v1/odoo/stock_account_value`, {account: '1231%', endDate: this.dateISOFormat(this.date)})
          .then(response => {
            if (response.data.success) {
              if (response.data.data) {
                self.data = response.data.data;
              }
              self.gridReady = true;
              loading.hide();
            }
          })
          .catch(error => {
            let errorMessage = '';
            if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad'
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar reporte de inventario valuado',
                text: errorMessage,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
            loading.hide();
          });
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
