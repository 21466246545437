<!-- =========================================================================================
    File Name: SalesmanComparisonStatistics.vue
    Description: Comparative sales by salesman
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 625px;">
            <ejs-daterangepicker
              ref="period1"
              floatLabelType="Auto"
              placeholder="Seleccione primer período"
              start="Year"
              depth="Year"
            >
              <e-presets>
                <e-preset label="Mes anterior" :start='lastMonthStart' :end='lastMonthEnd'></e-preset>
                <e-preset label="Mes actual" :start='currentMonthStart' :end='currentMonthEnd'></e-preset>
                <e-preset label="Año anterior" :start='lastYearStart' :end='lastYearEnd'></e-preset>
                <e-preset label="Año actual" :start='currentYearStart' :end='currentYearEnd'></e-preset>
              </e-presets>
            </ejs-daterangepicker>
          </div>
          <div class="mr-1" style="min-width: 425px;">
            <ejs-daterangepicker
              ref="period2"
              floatLabelType="Auto"
              placeholder="Seleccione segundo período"
              start="Year"
              depth="Year"
            >
              <e-presets>
                <e-preset label="Mes anterior" :start='lastMonthStart' :end='lastMonthEnd'></e-preset>
                <e-preset label="Mes actual" :start='currentMonthStart' :end='currentMonthEnd'></e-preset>
                <e-preset label="Año anterior" :start='lastYearStart' :end='lastYearEnd'></e-preset>
                <e-preset label="Año actual" :start='currentYearStart' :end='currentYearEnd'></e-preset>
              </e-presets>
            </ejs-daterangepicker>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getDashboard">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
          <div class="ml-1 mt-1">
            <b-button variant="primary" class="btn-icon rounded-circle" @click="getFile">
              <feather-icon icon="DownloadIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <div>
          <b-button variant="flat-success" @click="excelExport">Exportar a Excel</b-button>
          <ejs-pivotview
            ref="pivot"
            :dataSourceSettings="dataSourceSettings"
            :showGroupingBar="false"
            showFieldList=true
            locale='es-GT'
            allowExcelExport=true
          />
        </div>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {DateRangePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';// To load the culture based first day of week
import {PivotViewPlugin, GroupingBar, FieldList} from '@syncfusion/ej2-vue-pivotview';
import {TabPlugin} from '@syncfusion/ej2-vue-navigations';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

Vue.use(GridPlugin, AutoComplete);
Vue.use(DropDownListPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(PivotViewPlugin);
Vue.use(TabPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    daterangepicker: {
      placeholder: 'Seleccione período',
      startLabel: 'Fecha inicial',
      endLabel: 'Fecha final',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      selectedDays: 'Seleccione día inicial y final',
      days: 'Días',
      customRange: 'Otro período'
    },
    pivotview: {
      grandTotal: 'Gran total',
      total: 'Total',
      value: 'Valor',
      noValue: 'Sin valor',
      row: 'Fila',
      column: 'Columna',
      collapse: 'Colapsar',
      expand: 'Expandir',
      rowAxisPrompt: 'Arrastre fila aquí',
      columnAxisPrompt: 'Arrastre columna aquí',
      valueAxisPrompt: 'Arrastre valor aquí',
      filterAxisPrompt: 'Arrastre filtro aquí',
      filter: 'Filtro',
      filtered: 'Filtrado',
      sort: 'Ordenar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      calculatedField: 'Campo calculado',
      sum: 'Suma'
    },
    pivotfieldlist: {
      fieldList: 'Lista de campos',
      dropRowPrompt: 'Arrastre fila aquí',
      dropColPrompt: 'Arrastre columna aquí',
      dropValPrompt: 'Arrastre valor aquí',
      dropFilterPrompt: 'Arrastre fo;trp aquí',
      addPrompt: 'Agregar campo',
      centerHeader: 'Centrar encabezado:',
      add: 'Agregar',
      drag: 'Arrastrar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      error: 'Error',
      dropAction: 'El campo calculado no puede estar en otra región excepto el valor en eje.',
      search: 'Buscar',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      alert: 'Alerta',
      warning: 'Advertencia',
      ok: 'Aceptar',
      allFields: 'Todos los campos',
      noMatches: 'Sin coincidencias'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      currentYearStart: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
      currentYearEnd: new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 999),
      lastYearStart: new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0),
      lastYearEnd: new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59, 999),
      currentMonthStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      currentMonthEnd: new Date(),
      lastMonthStart: new Date(),
      lastMonthEnd: new Date(),
      startDate1: null,
      endDate1: null,
      startDate2: null,
      endDate2: null,
      dateFormatOptions: {
        type: 'dateTime',
        format: 'dd/MM/yyyy hh:mm a'
      },
      cardTitle: 'Comparativo de venta por promotor',
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 20},
      sortOptions: {
        columns: [{
          field: 'salesmanName',
          direction: 'Ascending'
        }, {
          field: 'customerName',
          direction: 'Ascending'
        }]
      },
      dataSourceSettings: {
        expandAll: false,
        values: [
          {
            name: 'transactions1',
            caption: 'Cant. 1'
          },
          {
            name: 'amount1',
            caption: 'Monto 1'
          },
          {
            name: 'transactions2',
            caption: 'Cant. 2'
          },
          {
            name: 'amount2',
            caption: 'Monto 2'
          }
        ],
        rows: [{
          name: 'salesmanName',
          caption: 'Promotor'
        }, {
          name: 'customerName',
          caption: 'Cliente'
        }],
        formatSettings: [
          {
            name: 'transactions1',
            format: 'N0'
          },
          {
            name: 'amount1',
            format: 'N2'
          },
          {
            name: 'transactions2',
            format: 'N0'
          },
          {
            name: 'amount2',
            format: 'N2'
          }
        ],
        filters: []
      },
      gridSettings: {columnWidth: '200px'},
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Filter],
    pivotview: [GroupingBar, FieldList]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  mounted() {
    this.currentMonthEnd = new Date(this.currentMonthEnd.setMonth(this.currentMonthEnd.getMonth() + 1));
    this.currentMonthEnd = new Date(this.currentMonthEnd.setMilliseconds(this.currentMonthEnd.getMilliseconds() - 1));
    this.lastMonthStart = new Date(this.lastMonthStart.setMonth(this.lastMonthEnd.getMonth() - 1));
    this.lastMonthStart.setDate(1);
    this.lastMonthEnd = new Date(this.lastMonthStart);
    this.lastMonthEnd.setMonth(this.lastMonthStart.getMonth() + 1);
    this.lastMonthEnd = new Date(this.lastMonthEnd.setDate(this.lastMonthEnd.getDate() - 1));
  },
  methods: {
    formatDate(d) {
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    toolbarClick(args) {
      if (args.item.id === 'grid_excelexport') {
        this.$refs.grid.excelExport({fileName: `Comparativo de ventas por promotor.xlsx`});
      }
    },
    excelExport() {
      const pivot = this.$refs.pivot.ej2Instances;
      pivot.excelExport();
    },
    setStartTime(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    },
    setEndTime(date) {
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      date.setMilliseconds(999);
      return date;
    },
    getDashboard() {
      let startDate1 = this.$refs.period1.getSelectedRange().startDate;
      let endDate1 = this.$refs.period1.getSelectedRange().endDate;
      let startDate2 = this.$refs.period2.getSelectedRange().startDate;
      let endDate2 = this.$refs.period2.getSelectedRange().endDate;
      if (startDate1 && endDate1) {
        if (startDate2 && endDate2) {
          startDate1 = this.setStartTime(startDate1);
          startDate2 = this.setStartTime(startDate2);
          endDate1 = this.setEndTime(endDate1);
          endDate2 = this.setEndTime(endDate2);
          const loading = this.$loading.show();
          const self = this;
          const data = {
            startDate: this.dateISOFormat(startDate1),
            endDate: this.dateISOFormat(endDate1),
            startDate2: this.dateISOFormat(startDate2),
            endDate2: this.dateISOFormat(endDate2)
          };
          this.$http.post(`${this.api}/v1/statistics/comparison_salesman`, data)
            .then(response => {
              if (response.data.success) {
                self.data = [];
                // self.chart.series = [];
                if (response.data.data) {
                  /*
                                  const data1 = [];
                                  const data2 = [];
                                  let amount1 = 0;
                                  let amount2 = 0;
                                  let salesman = null;
                                  for (let i = 0; i < response.data.data.length; i++) {
                                    if (salesman != response.data.data[i].salesmanName) {
                                      if (salesman != null) {
                                        data1.push({x: salesman, y: amount1});
                                        data2.push({x: salesman, y: amount2});
                                        amount1 = 0;
                                        amount2 = 0;
                                      }
                                      salesman = response.data.data[i].salesmanName;
                                    }
                                    amount1 += response.data.data[i].amount1;
                                    amount2 += response.data.data[i].amount2;
                                  }
                  */
                  self.data = [...response.data.data];
                  self.$refs.pivot.ej2Instances.dataSourceSettings.dataSource = [...response.data.data];
                  self.$refs.pivot.ej2Instances.gridSettings = self.gridSettings;
                }
              }
              loading.hide();
              self.gridReady = true;
            })
            .catch(error => {
              let errorMessage = '';
              if (error.message) {
                errorMessage = error.message;
              } else if (error.response) {
                errorMessage = error.response.data.ErrorMessage;
              } else {
                errorMessage = 'Error de conectividad'
              }
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al cargar reporte de ventas por promotor',
                  text: errorMessage,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
              loading.hide();
            });
        }
      }
    },
    refreshData(card) {
      this.getDashboard();
      if (typeof card !== 'undefined') {
        card.removeRefreshAnimation(500);
      }
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    getFile() {
      let startDate1 = this.$refs.period1.getSelectedRange().startDate;
      let endDate1 = this.$refs.period1.getSelectedRange().endDate;
      let startDate2 = this.$refs.period2.getSelectedRange().startDate;
      let endDate2 = this.$refs.period2.getSelectedRange().endDate;
      if (startDate1 && endDate1) {
        if (startDate2 && endDate2) {
          startDate1 = this.setStartTime(startDate1);
          startDate2 = this.setStartTime(startDate2);
          endDate1 = this.setEndTime(endDate1);
          endDate2 = this.setEndTime(endDate2);
          const loading = this.$loading.show();
          const self = this;
          const data = {
            startDate: this.dateISOFormat(startDate1),
            endDate: this.dateISOFormat(endDate1),
            startDate2: this.dateISOFormat(startDate2),
            endDate2: this.dateISOFormat(endDate2)
          };
          this.$http.post(`${this.api}/v1/statistics/comparison_salesman_file`, data)
            .then(response => {
              if (response.data.success) {
                if (response.data.data) {
                  const sd1 = startDate1.toLocaleDateString('es-GT').replace(/\//g, "-");
                  const ed1 = endDate1.toLocaleDateString('es-GT').replace(/\//g, "-");
                  const sd2 = startDate2.toLocaleDateString('es-GT').replace(/\//g, "-");
                  const ed2 = endDate2.toLocaleDateString('es-GT').replace(/\//g, "-");
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(self.b64toBlob(response.data.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
                  link.download = `Comparativo por promotor - ${sd1} a ${ed1} vs ${sd2} a ${ed2}.xlsx`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
                loading.hide();
              }
            })
            .catch(error => {
              let errorMessage = '';
              if (error.message) {
                errorMessage = error.message;
              } else if (error.response) {
                errorMessage = error.response.data.ErrorMessage;
              } else {
                errorMessage = 'Error de conectividad'
              }
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al genrear archivo de Streamline',
                  text: errorMessage,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              }, {timeout});
              loading.hide();
            });
        }
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
