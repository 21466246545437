<!-- =========================================================================================
    File Name: StockTransit.vue
    Description: List of products in transit
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 270px;">
            <ejs-datepicker ref="date" v-model="date" floatLabelType="Auto" placeholder="Calcular al día"/>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getData">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <ejs-grid
          id="grid"
          ref="grid"
          :dataSource="data"
          locale='es-GT'
          :toolbar='toolbarOptions'
          :allowPaging="true"
          :pageSettings='pageSettings'
          :allowSorting='true'
          :allowExcelExport='true'
          :toolbarClick='toolbarClick'
          :allowFiltering='true'
          :filterSettings='filterOptions'
          :frozenColumns='3'
        >
          <e-columns>
            <e-column field="code" headerText="Código producto" width="200" clipMode='EllipsisWithTooltip'/>
            <e-column field="isbn" headerText="ISBN" width="180"/>
            <e-column field="name" headerText="Nombre producto" width="300" clipMode='EllipsisWithTooltip'/>
            <e-column field="categoryName" headerText="Categoría" width="150" :filter='checkBoxFilter'/>
            <e-column field="editorial" headerText="Editorial" width="250" :filter='checkBoxFilter'/>
            <e-column field="editorialOrganization" headerText="Casa matriz" width="250" :filter='checkBoxFilter'/>
            <e-column field="version" headerText="Versión" width="170" :filter='checkBoxFilter'/>
            <e-column field="family1" headerText="Familia 1" width="220" :filter='checkBoxFilter'/>
            <e-column field="family2" headerText="Familia 2" width="220" :filter='checkBoxFilter'/>
            <e-column field="family3" headerText="Familia 3" width="220" :filter='checkBoxFilter'/>
            <e-column field="size" headerText="Tamaño" width="220" :filter='checkBoxFilter'/>
            <e-column field='cost' headerText='Costo' type="number" textAlign='Right' format="N6" width="160"/>
            <e-column field='transit1' headerText='Lote 1' type="number" textAlign='Right' format="N0" width="160"/>
            <e-column field='transit2' headerText='Lote 2' type="number" textAlign='Right' format="N0" width="160"/>
            <e-column field='transit3' headerText='Lote 3' type="number" textAlign='Right' format="N0" width="160"/>
            <e-column field='transit4' headerText='Lote 4' type="number" textAlign='Right' format="N0" width="160"/>
            <e-column field='transit5' headerText='Directo' type="number" textAlign='Right' format="N0" width="160"/>
          </e-columns>
        </ejs-grid>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Freeze, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
// To load the culture based first day of week

Vue.use(GridPlugin, AutoComplete);
Vue.use(DatePickerPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datepicker: {
      placeholder: 'Seleccione fecha a calcular',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      today: 'Hoy'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      date: new Date(),
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 25},
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Freeze, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter],
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  methods: {
    formatDate (field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    formatPrintDate(d) {
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}-${(d.getMonth() + 1).toString()
        .padStart(2, '0')}-${d.getFullYear()}`;
    },
    toolbarClick (args) {
      if (args.item.id === 'grid_excelexport') {
        this.$refs.grid.excelExport({fileName: `Productos en tránsito al ${this.formatPrintDate(this.date)}.xlsx`});
      }
    },
    getData() {
      if (this.date) {
        const loading = this.$loading.show();
        this.data = [];
        const self = this;
        this.$http.post(`${this.api}/v1/reports/stock_transit`, {
          startDate: this.dateISOFormat(this.date)
        })
          .then(response => {
            if (response.data.success) {
              if (response.data.data) {
                self.data = [...response.data.data];
              }
              self.gridReady = true;
              loading.hide();
            }
          })
          .catch(error => {
            let errorMessage = '';
            if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad'
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar reporte de productos en tránsito',
                text: errorMessage,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
            loading.hide();
          });
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
