<!-- =========================================================================================
    File Name: ProjectsAnalyticDetailReport.vue
    Description: Balance by analytic account and date for projects
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 495px;">
            <ejs-daterangepicker ref="period" :startDate="startDate" :endDate="endDate" floatLabelType="Auto" placeholder="Seleccione período"/>
          </div>
          <div class="mr-1 w-25">
            <ejs-dropdownlist
              ref="warehouse"
              v-model="accountId"
              floatLabelType="Auto"
              placeholder="Cuenta analítica"
              highlight=true
              :dataSource="accounts"
              :fields="{text: 'name', value: 'id'}"
              :query='accountQuery'
              allowFiltering=true
              :filtering='accountFiltering'
              showClearButton=true
            />
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getReport">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <div>
          <ejs-grid
            id="grid"
            ref="grid"
            :dataSource="data"
            locale='es-GT'
            :toolbar='toolbarOptions'
            :allowPaging="true"
            :pageSettings='pageSettings'
            :allowSorting='false'
            :sortSettings='sortOptions'
            :allowExcelExport='true'
            :toolbarClick='toolbarClick'
            :allowFiltering='true'
            :filterSettings='filterOptions'
            allowGrouping=true
            :groupSettings='groupOptions'
          >
            <e-columns>
              <e-column field='date' headerText='Fecha' width="120" :valueAccessor="formatDate"/>
              <e-column field='name' headerText='Cuenta contable' width="250" clipMode='EllipsisWithTooltip' :filter='checkBoxFilter'/>
              <e-column field='journal' headerText='Diario' width="300" clipMode='EllipsisWithTooltip'/>
              <e-column field='partner' headerText='Empresa' width="300" clipMode='EllipsisWithTooltip'/>
              <e-column field='ref' headerText='Referencia' width="200" clipMode='EllipsisWithTooltip'/>
              <e-column field='income' headerText='Ingresos' type="number" textAlign='Right' width="130" format="N2" :footerTemplate="footerTemplate"/>
              <e-column field='expenses' headerText='Egresos' type="number" textAlign='Right' width="130" format="N2" :footerTemplate="footerTemplate"/>
            </e-columns>
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                  <e-column type="Sum" field="income" format="N2" :groupFooterTemplate="footerTemplate"></e-column>
                  <e-column type="Sum" field="expenses" format="N2" :groupFooterTemplate="footerTemplate"></e-column>
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {Query} from "@syncfusion/ej2-data";
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter, Aggregate} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {DateRangePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';// To load the culture based first day of week
import {TabPlugin} from '@syncfusion/ej2-vue-navigations';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

Vue.use(GridPlugin, AutoComplete);
Vue.use(DropDownListPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(TabPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    daterangepicker: {
      placeholder: 'Seleccione período',
      startLabel: 'Fecha inicial',
      endLabel: 'Fecha final',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      selectedDays: 'Seleccione día inicial y final',
      days: 'Días'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      startDate: null,
      endDate: null,
      dateFormatOptions: {
        type: 'dateTime',
        format: 'dd/MM/yyyy hh:mm a'
      },
      cardTitle: 'Resumen de venta por producto',
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 15},
      sortOptions: {
        columns: [{
          field: 'Categoría',
          direction: 'Ascending'
        }, {
          field: 'Producto',
          direction: 'Ascending'
        }]
      },
      groupOptions: { showDropArea: false, columns: ['name'] },
      footerTemplate() {
        return {
          template: Vue.component('groupFooterTemplate', {
            data() {
              return {
                data: {}
              };
            },
            template: `<span>{{data.Sum}}</span>`
          })
        }
      },
      accountId: null,
      accountQuery: new Query().select(['name', 'id']).take(10),
      accounts: [],
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      },
      journalFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Group, Filter, Aggregate]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  mounted() {
    const self = this;
    this.$http
      .post(`${this.api}/v1/odoo/analytic_accounts`, {filterProjects: true})
      .then(response => {
        if (response.data.success) {
          self.accounts = response.data.data;
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar listado de cuentas analíticas',
              text: response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
        }
      })
      .catch(error => {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cargar listado de cuentas analíticas',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      });
  },
  methods: {
    accountFiltering (e) {
      const searchData = JSON.parse(JSON.stringify(this.accounts));
      if (e.text == '') {
        e.updateData(searchData);
      } else {
        let query = new Query().select(['name', 'id']);
        query = (e.text !== '') ? query.where('name', 'contains', e.text, true, true) : query;
        e.updateData(searchData, query);
      }
    },
    formatDate (field, data) {
      const t = data[field].split('-');
      const d = new Date(+t[0], t[1] - 1, +t[2]);
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    formatCurrency (field, data) {
      let value = '';
      if (data[field] !== 0) {
        value = data[field].toLocaleString('es-GT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      return value;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    toolbarClick (args) {
      let accountName = 'TODAS';
      if (args.item.id === 'grid_excelexport') {
        const incomes = Math.round(this.data.map(item => item.income).reduce((prev, next) => prev + next) * 100) / 100;
        const expenses = Math.round(this.data.map(item => item.expenses).reduce((prev, next) => prev + next) * 100) / 100;
        const account = this.accounts.filter(item => item.id == this.accountId);
        if (account.length > 0) {
          accountName = account[0].name;
        }
        const excelProperties = {
          fileName: `Estado de cuenta analítica detallado del ${this.startDate.toLocaleDateString()} al ${this.endDate.toLocaleDateString()}.xlsx`,
          hierarchyExportMode: "Expanded",
          header: {
            headerRows: 4,
            rows: [
              { cells: [{value: 'Estado de cuenta analítica detallado', style: {fontSize: 20, bold: true}}] },
              { cells: [{}, {value: 'Cuenta:', style: {fontSize: 16}}, {value: accountName, style: {fontSize: 16}}] },
              { cells: [{}, {value: 'Período:', style: {fontSize: 16}}, {value: this.startDate.toLocaleDateString(), style: {fontSize: 16}}, {value: this.endDate.toLocaleDateString(), style: {fontSize: 14}}]}
            ]
          },
          footer: {
            footerRows: 2,
            rows: [
              { cells: [
                {}, {}, {}, {},
                { value: 'TOTAL:', style: { hAlign: 'Right', fontSize: 14, bold: true } },
                { value: incomes, style: { hAlign: 'Right', fontSize: 14, bold: true } },
                { value: expenses, style: { hAlign: 'Right', fontSize: 14, bold: true } }
              ] }
            ]
          }
        }
        this.$refs.grid.excelExport(excelProperties);
      }
    },
    getReport() {
      this.startDate = this.$refs.period.getSelectedRange().startDate;
      this.endDate = this.$refs.period.getSelectedRange().endDate;
      if (this.startDate) {
        if (this.endDate) {
          this.startDate.setHours(0);
          this.startDate.setMinutes(0);
          this.startDate.setSeconds(0);
          this.endDate.setHours(23);
          this.endDate.setMinutes(59);
          this.endDate.setSeconds(59);
          if (this.startDate < this.endDate) {
            this.cardTitle = `Estado de cuenta analítica del ${this.startDate.toLocaleDateString()} al ${this.endDate.toLocaleDateString()}`;
            const loading = this.$loading.show();
            const self = this;
            this.$http.post(`${this.api}/v1/odoo/analytic_balance_detail`, {
              startDate: this.dateISOFormat(this.startDate),
              endDate: this.dateISOFormat(this.endDate),
              accountId: this.accountId
            })
              .then(response => {
                self.cardTitle = `Estado de cuenta analítica`;
                if (response.data.success) {
                  if (response.data.data) {
                    self.data = response.data.data;
                  }
                  self.gridReady = true;
                  loading.hide();
                }
              })
              .catch(error => {
                let errorMessage = '';
                if (error.message) {
                  errorMessage = error.message;
                } else if (error.response) {
                  errorMessage = error.response.data.ErrorMessage;
                } else {
                  errorMessage = 'Error de conectividad'
                }
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al cargar reporte estado de cuenta analítica',
                    text: errorMessage,
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                }, {timeout});
                loading.hide();
              });
          }
        }
      }
    },
    refreshData(card) {
      this.getReport();
      if (typeof card !== 'undefined') {
        card.removeRefreshAnimation(500);
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
