<template>
  <transition>
    <div>
      <div class="px-2 py-1">
        <div class="row justify-content-between">
          <div class="ml-1">
            <h2 class="text-primary">
              <img src="@/assets/images/logo/logo.png" width="70" alt="Informes SBG"/>
              Informes SBG
            </h2>
          </div>
          <div class="justify-content-end mr-1">
            <b-navbar-nav class="nav align-items-center ml-auto">
              <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
              >
                <template #button-content>
                  <div>
                    <p class="user-name font-weight-bolder mb-0">
                      {{ userInfo.displayName }}
                    </p>
                    <span class="user-status">{{ userInfo.branchName }}</span>
                  </div>
                  <b-avatar
                    size="40"
                    variant="light-primary"
                    badge
                    :src="userImage"
                    class="badge-minimal"
                    badge-variant="success"
                  />
                </template>

                <b-dropdown-item link-class="d-flex align-items-center" @click="goToProfile">
                  <feather-icon
                    size="16"
                    icon="UserIcon"
                    class="mr-50"
                  />
                  <span>Perfil</span>
                </b-dropdown-item>

                <b-dropdown-divider/>

                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                  <feather-icon
                    size="16"
                    icon="LogOutIcon"
                    class="mr-50"
                  />
                  <span>Cerrar sesión</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </div>
        </div>
      </div>
      <div v-if="loaded" class="container-fluid">
        <b-row cols="5">
          <b-col v-if="moduleAccess('INF')" col class="mb-2 cursor-pointer" @click="$router.push({name: 'infraestructure'})">
            <b-card
              :img-src="require('@/assets/images/pages/infra.png')"
              img-alt="Infraestructura"
              img-top
              no-body
              class="h-100"
            >
              <b-card-body>
                <b-card-title>Infraestructura</b-card-title>
                <b-card-text>
                  Manejo de usuarios, permisos y configuraciones generales
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-if="moduleAccess('CON')" col class="mb-2 cursor-pointer" @click="$router.push({name: 'accounting'})">
            <b-card
              :img-src="require('@/assets/images/pages/accounting.png')"
              img-alt="Contabilidad"
              img-top
              no-body
              class="h-100"
            >
              <b-card-body>
                <b-card-title>Contabilidad</b-card-title>
                <b-card-text>
                  Reportes contables e informes financieros
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-if="moduleAccess('EST')" col class="mb-2 cursor-pointer" @click="$router.push({name: 'statistics'})">
            <b-card
              :img-src="require('@/assets/images/pages/statistics.png')"
              img-alt="Estadísticas"
              img-top
              no-body
              class="h-100"
            >
              <b-card-body>
                <b-card-title>Estadísticas</b-card-title>
                <b-card-text>
                  Estadísticas y gráficas de venta
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-if="moduleAccess('REP')" col class="mb-2 cursor-pointer" @click="$router.push({name: 'reports'})">
            <b-card
              :img-src="require('@/assets/images/pages/reports.png')"
              img-alt="Reportes"
              img-top
              no-body
              class="h-100"
            >
              <b-card-body>
                <b-card-title>Reportes</b-card-title>
                <b-card-text>
                  Reportes de inventario y ventas
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-if="moduleAccess('PRJ')" col class="mb-2 cursor-pointer" @click="$router.push({name: 'projects'})">
            <b-card
              :img-src="require('@/assets/images/pages/projects.png')"
              img-alt="Reportes"
              img-top
              no-body
              class="h-100"
            >
              <b-card-body>
                <b-card-title>Proyectos</b-card-title>
                <b-card-text>
                  Informes para proyectos
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col v-if="moduleAccess('CAT')" col class="mb-2 cursor-pointer" @click="$router.push({name: 'catalogs'})">
            <b-card
              :img-src="require('@/assets/images/pages/catalogs.png')"
              img-alt="Catálogos"
              img-top
              no-body
              class="h-100"
            >
              <b-card-body>
                <b-card-title>Catálogos</b-card-title>
                <b-card-text>
                  Listados y catálogos principales
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </transition>
</template>

<script>
import {BRow, BCol, BCard, BCardBody, BCardTitle, BCardText, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar} from 'bootstrap-vue';
import localForage from 'localforage';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  },
  data() {
    return {
      loaded: false,
      modules: []
    }
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    },
    userInfo() {
      return this.$store.state.fastway.userInfo;
    },
    userImage() {
      let image = '@/assets/images/avatars/13-small.png';
      if (this.$store.state.fastway.image != '') {
        image = this.$store.state.fastway.image;
      }
      return image;
    }
  },
  watch: {
    // api() {
    //   console.log('tipo: ', typeof this.api);
    //   if (typeof this.api == 'string') {
    //     console.log('parametros: ', this.$store.state.fastway.parameters);
    //     console.log('api: ', this.api);
    //     const api = this.$store.state.fastway.parameters.apiURL;
    //     if (this.$store.state.fastway.userInfo.token != '') {
    //       console.log(api);
    //       const self = this;
    //       this.$http
    //         .post(`${api}/users/modules`, {
    //           userId: this.$store.state.fastway.userInfo.userId,
    //           token: this.$store.state.fastway.userInfo.token
    //         })
    //         .then(response => {
    //           if (response.data.success) {
    //             if (response.data.data) {
    //               self.modules = response.data.data;
    //               self.loaded = true;
    //             }
    //           } else {
    //             self.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: 'Error al validar módulos válidos para el usuario',
    //                 text: response.data.message,
    //                 icon: 'AlertCircleIcon',
    //                 variant: 'danger'
    //               }
    //             });
    //           }
    //         })
    //         .catch(error => {
    //           let errorMessage = '';
    //           if (error.message) {
    //             errorMessage = error.message;
    //           } else if (error.response) {
    //             errorMessage = error.response.data.ErrorMessage;
    //           } else {
    //             errorMessage = 'Error de conectividad';
    //           }
    //           self.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Error al validar accesos a módulos',
    //               text: errorMessage,
    //               icon: 'AlertCircleIcon',
    //               variant: 'danger'
    //             }
    //           });
    //         });
    //     }
    //   }
    // }
  },
  mounted() {
    this.modules = [];
    if (typeof this.api == 'string') {
      // const api = this.$store.state.fastway.parameters.apiURL;
      if (this.$store.state.fastway.userInfo.token != '') {
        const self = this;
        this.$http
          .post(`${this.api}/users/modules`, {
            userId: this.$store.state.fastway.userInfo.userId,
            token: this.$store.state.fastway.userInfo.token
          })
          .then(response => {
            if (response.data.success) {
              if (response.data.data) {
                for (let i = 0; i < response.data.data.length; i++) {
                  self.modules.push(response.data.data[i].module);
                }
                self.loaded = true;
              }
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error al validar módulos válidos para el usuario',
                  text: response.data.message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger'
                }
              });
            }
          })
          .catch(error => {
            let errorMessage = '';
            if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad';
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al validar accesos a módulos',
                text: errorMessage,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            });
          });
      }
    }
  },
  methods: {
    goToProfile() {
      this.$router.push('/profile');
    },
    logout() {
      const {prefix} = this.config;
      localForage.removeItem(`${prefix}UserInfo`);
      localForage.removeItem(`${prefix}UserId`);
      localForage.removeItem(`${prefix}Login`);
      localForage.removeItem(`${prefix}UserName`);
      localForage.removeItem(`${prefix}Token`);
      this.$store.dispatch('fastway/clearUserInfo');
      this.$router.push('/login');
    },
    load(module) {
      window.location.assign(`/${module}`);
    },
    moduleAccess(module) {
      return this.modules.includes(module);
    }
  }
};
</script>

<style>
.card-group .card {
  max-width: 25%;
}

.card-deck .card {
  max-width: calc(25% - 30px);
}
</style>
