<!-- =========================================================================================
    File Name: CustomersSalesman.vue
    Description: Customers by salesman
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card v-show="gridReady">
      <b-container>
        <ejs-tab ref="tabs">
          <div class="e-tab-header">
            <div>Lista</div>
            <div>Tabla</div>
          </div>
          <div class="e-content">
            <div>
              <ejs-grid
                id="grid"
                ref="grid"
                :dataSource="data"
                locale='es-GT'
                :toolbar='toolbarOptions'
                :allowPaging="true"
                :pageSettings='pageSettings'
                :allowSorting='true'
                :sortSettings='sortOptions'
                :allowExcelExport='true'
                :allowGrouping='true'
                :toolbarClick='toolbarClick'
                :allowFiltering='true'
                :filterSettings='filterOptions'
                :dataBound="onGridDataBound"
              >
                <e-columns>
                  <e-column field='Promotor' width="250" clipMode='EllipsisWithTooltip' :filter='checkBoxFilter'/>
                  <e-column field='Cliente' width="250" clipMode='EllipsisWithTooltip'/>
                  <e-column field='Unidades 2020' type="number" textAlign='Right' format="N0" width="180"/>
                  <e-column field='Monto 2020' type="number" textAlign='Right' format="N2" width="170"/>
                  <e-column field='Unidades 2019' type="number" textAlign='Right' format="N0" width="180"/>
                  <e-column field='Monto 2019' type="number" textAlign='Right' format="N2" width="170"/>
                  <e-column field='Unidades 2018' type="number" textAlign='Right' format="N0" width="180"/>
                  <e-column field='Monto 2018' type="number" textAlign='Right' format="N2" width="170"/>
                  <e-column field='Unidades 2017' type="number" textAlign='Right' format="N0" width="180"/>
                  <e-column field='Monto 2017' type="number" textAlign='Right' format="N2" width="170"/>
                  <e-column field='Unidades 2016' type="number" textAlign='Right' format="N0" width="180"/>
                  <e-column field='Monto 2016' type="number" textAlign='Right' format="N2" width="170"/>
                  <e-column field='Unidades 2015' type="number" textAlign='Right' format="N0" width="180"/>
                  <e-column field='Monto 2015' type="number" textAlign='Right' format="N2" width="170"/>
                </e-columns>
              </ejs-grid>
            </div>
            <div class="flex">
              <b-button variant="flat-success" @click="excelExport">Exportar a Excel</b-button>
              <ejs-pivotview
                ref="pivot"
                :dataSourceSettings="dataSourceSettings"
                showGroupingBar=true
                showFieldList=true
                locale='es-GT'
                allowExcelExport=true
              />
            </div>
          </div>
        </ejs-tab>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';// To load the culture based first day of week
import {PivotViewPlugin, GroupingBar, FieldList} from '@syncfusion/ej2-vue-pivotview';
import {TabPlugin} from '@syncfusion/ej2-vue-navigations';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

Vue.use(GridPlugin, AutoComplete);
Vue.use(DropDownListPlugin);
Vue.use(PivotViewPlugin);
Vue.use(TabPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    pivotview: {
      grandTotal: 'Gran total',
      total: 'Total',
      value: 'Valor',
      noValue: 'Sin valor',
      row: 'Fila',
      column: 'Columna',
      collapse: 'Colapsar',
      expand: 'Expandir',
      rowAxisPrompt: 'Arrastre fila aquí',
      columnAxisPrompt: 'Arrastre columna aquí',
      valueAxisPrompt: 'Arrastre valor aquí',
      filterAxisPrompt: 'Arrastre filtro aquí',
      filter: 'Filtro',
      filtered: 'Filtrado',
      sort: 'Ordenar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      calculatedField: 'Campo calculado',
      sum: 'Suma'
    },
    pivotfieldlist: {
      fieldList: 'Lista de campos',
      dropRowPrompt: 'Arrastre fila aquí',
      dropColPrompt: 'Arrastre columna aquí',
      dropValPrompt: 'Arrastre valor aquí',
      dropFilterPrompt: 'Arrastre fo;trp aquí',
      addPrompt: 'Agregar campo',
      centerHeader: 'Centrar encabezado:',
      add: 'Agregar',
      drag: 'Arrastrar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      error: 'Error',
      dropAction: 'El campo calculado no puede estar en otra región excepto el valor en eje.',
      search: 'Buscar',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      alert: 'Alerta',
      warning: 'Advertencia',
      ok: 'Aceptar',
      allFields: 'Todos los campos',
      noMatches: 'Sin coincidencias'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      dataSourceSettings: {
        dataSource: [],
        expandAll: false,
        values: [{name: 'Unidades 2020'}, {name: 'Monto 2020'}],
        rows: [{name: 'Promotor'}, {name: 'Cliente'}],
        formatSettings: [{
          name: 'Unidades 2020',
          format: 'N0'
        }, {
          name: 'Monto 2020',
          format: 'N2'
        }],
        filters: []
      },
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      startDate: null,
      endDate: null,
      dateFormatOptions: {
        type: 'dateTime',
        format: 'dd/MM/yyyy hh:mm a'
      },
      cardTitle: 'Clientes por promotor',
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 25},
      sortOptions: {
        columns: [{
          field: 'Promotor',
          direction: 'Ascending'
        }, {
          field: 'Cliente',
          direction: 'Ascending'
        }]
      },
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      },
      journalFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Group, Filter],
    pivotview: [GroupingBar, FieldList]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  mounted() {
    this.getReport();
  },
  methods: {
    toolbarClick (args) {
      if (args.item.id === 'grid_excelexport') {
        this.$refs.grid.excelExport({fileName: `Clientes por promotor.xlsx`});
      }
    },
    excelExport() {
      const pivot = this.$refs.pivot.ej2Instances;
      pivot.excelExport();
    },
    onGridDataBound() {
      if (this.gridReady) {
        this.$refs.pivot.ej2Instances.dataSourceSettings.dataSource = this.data;
      }
    },
    getReport() {
      const loading = this.$loading.show();
      const self = this;
      this.$http.post(`${this.api}/v1/catalogs/customers_salesman`)
        .then(response => {
          if (response.data.success) {
            self.gridReady = true;
            if (response.data.data) {
              self.data = response.data.data;
            }
            loading.hide();
          }
        })
        .catch(error => {
          let errorMessage = '';
          if (error.message) {
            errorMessage = error.message;
          } else if (error.response) {
            errorMessage = error.response.data.ErrorMessage;
          } else {
            errorMessage = 'Error de conectividad'
          }
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar reporte de ventas por promotor',
              text: errorMessage,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
          loading.hide();
        });
    }
  }
}

</script>

<style lang="scss">
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
