<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <img src="@/assets/images/logo/logo.png" width="70" alt="Informes SBG"/>
          Informes SBG
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Bienvenido a Informes SBG
          </b-card-title>
          <b-card-text v-if="accessSent" class="mb-2">
            Se ha enviado un correo de acceso a la dirección de correo electrónico especificada, por favor siga las instrucciones en el mismo para acceder
          </b-card-text>
          <b-card-text v-else class="mb-2">
            Por favor ingrese con su dirección de correo electrónico institucional
          </b-card-text>

          <!-- form -->
          <b-button
            v-if="accessSent"
            type="submit"
            variant="primary"
            block
            @click="accessSent = false"
          >
            Solicitar nuevo acceso
          </b-button>
          <validation-observer v-else ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Correo electrónico"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Correo electrónico"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userId"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Especifique correo electrónico registrado"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                v-if="config"
                type="submit"
                variant="primary"
                block
                :disabled="loginDisabled"
                @click="validationForm"
              >
                Solicitar acceso
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver, localize} from 'vee-validate';
import es from 'vee-validate/dist/locale/es.json';
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton
} from 'bootstrap-vue';
import {required, email} from '@validations';
import {togglePasswordVisibility} from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

localize('es', es);
// ValidationProvider.localize('es');

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      accessSent: false,
      loginDisabled: false,
      status: '',
      userId: '',
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email
    };
  },
  computed: {
    config() {
      return store.state.fastway.parameters;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    getMenu(menu) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].options) {
          if (menu[i].options.length > 0) {
            menu[i].submenu = this.getMenu(menu[i].options);
          }
        }
      }
      return menu;
    },
    validationForm() {
      const self = this;
      this.loginDisabled = true;
      this.$refs.loginValidation.validate()
        .then(success => {
          if (success) {
            self.$store.dispatch('fastway/updateNavMenuItems', []);
            self.$http.post(`${self.config.apiURL}/users/send_access_token`, { email: this.userId })
              .then(response => {
                if (response.data.success) {
                  if (response.data.data.token == '') {
                    const timeout = 7000;
                    self.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Acceso denegado',
                        text: 'Usuario no registrado',
                        icon: 'AlertCircleIcon',
                        variant: 'danger'
                      }
                    }, {timeout});
                  } else {
                    self.accessSent = true;
                  }
                }
                self.loginDisabled = false;
              })
              .catch(error => {
                self.loginDisabled = false;
                let errorMessage = '';
                if (error.message) {
                  errorMessage = error.message;
                } else if (error.response) {
                  errorMessage = error.response.data.ErrorMessage;
                } else {
                  errorMessage = 'Error de conectividad';
                }
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al validar credenciales',
                    text: errorMessage,
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                });
              });
          } else {
            this.loginDisabled = false;
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
