<template>
  <transition name="fade">
    <div>
      <b-card title="Informes SBG">
        <b-card-text>Seleccione una opción en el menú a la izquierda.</b-card-text>
      </b-card>
    </div>
  </transition>
</template>

<script>
import {BCard, BCardText} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BCard,
    BCardText
  },
  mounted() {
    this.$store.dispatch('fastway/updateNavMenuItems', []);
    if (this.$store.state.fastway.userInfo.token != '') {
      const self = this;
      this.$http
        .post(`${this.$store.state.fastway.parameters.apiURL}/users/options`, {
          userId: this.$store.state.fastway.userInfo.userId,
          token: this.$store.state.fastway.userInfo.token,
          module: 'CAT'
        })
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              const menu = self.getMenu(response.data.data);
              self.$store.dispatch('fastway/updateNavMenuItems', menu);
              self.$forceUpdate();
            }
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al validar opciones de usuario',
                text: response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            });
          }
        })
        .catch(error => {
          let errorMessage = '';
          if (error.message) {
            errorMessage = error.message;
          } else if (error.response) {
            errorMessage = error.response.data.ErrorMessage;
          } else {
            errorMessage = 'Error de conectividad';
          }
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al validar credenciales',
              text: errorMessage,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          });
        });
    }
  },
  methods: {
    getMenu(menu) {
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].children) {
          if (menu[i].children.length > 0) {
            menu[i].submenu = this.getMenu(menu[i].children);
          }
        }
      }
      return menu;
    }
  }
};
</script>

<style lang="scss">
</style>
