import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '@/views/Login';
import ValidateToken from '@/views/ValidateToken';
import Error404 from '@/views/error/Error404';
import Home from '@/views/Home';
import Profile from '@/views/infra/users/Profile';
import InfraHome from '@/views/infra/InfraHome';
import Parameters from '@/views/infra/parameters/Parameters';
import Parameter from '@/views/infra/parameters/Parameter';
import Lists from '@/views/infra/lists/Lists';
import List from '@/views/infra/lists/List';
import Roles from '@/views/infra/roles/Roles';
import Role from '@/views/infra/roles/Role';
import Users from '@/views/infra/users/Users';
import User from '@/views/infra/users/User';
import Branches from '@/views/infra/branches/Branches';
import Branch from '@/views/infra/branches/Branch';
import StatisticsHome from '@/views/statistics/StatisticsHome';
import SalesmanSaleStatistics from '@/views/statistics/salesman/SalesmanSaleStatistics';
import SalesmanComparisonStatistics from '@/views/statistics/salesman/SalesmanComparisonStatistics';
import SalesmanDonationStatistics from '@/views/statistics/salesman/SalesmanDonationStatistics';
import SalesmanPaymentStatistics from '@/views/statistics/salesman/SalesmanPaymentStatistics';
import SalesmanProductStatistics from '@/views/statistics/salesman/SalesmanProductStatistics';
import ChannelSaleStatistics from '@/views/statistics/channel/ChannelSaleStatistics';
import CustomerComparisonStatistics from '@/views/statistics/customer/CustomerComparisonStatistics';
import ProductSaleStatistics from '@/views/statistics/product/ProductSaleStatistics';
import ProductVersionStatistics from '@/views/statistics/product/ProductVersionStatistics';
import ProductCostEvolution from '@/views/statistics/product/ProductCostEvolution';
import ReportsHome from '@/views/reports/ReportsHome';
import StockReport from '@/views/reports/stock/StockReport';
import StockRotation from '@/views/reports/stock/StockRotation';
import Streamline from '@/views/reports/stock/Streamline';
import StockTransit from '@/views/reports/stock/StockTransit';
import ProductSalesReport from '@/views/reports/sales/ProductSalesReport';
import ProductSummaryReport from '@/views/reports/sales/ProductSummaryReport';
import CatalogsHome from '@/views/catalogs/CatalogsHome';
import CustomersSalesman from '@/views/catalogs/CustomersSalesman';
import ProductPricelist from '@/views/catalogs/ProductPricelist';
import AccountingHome from '@/views/accounting/AccountingHome';
import ReceivablesAging from '@/views/accounting/ReceivablesAging';
import CustomerBalanceByPeriod from '@/views/accounting/CustomerBalanceByPeriod';
import Ledger from '@/views/accounting/Ledger';
import StockValuation from '@/views/accounting/StockValuation';
import LowTrafficStock from '@/views/accounting/LowTrafficStock';
import AnalyticBalanceReport from '@/views/reports/analytic/AnalyticBalanceReport';
import AnalyticByDateReport from '@/views/reports/analytic/AnalyticByDateReport';
import AnalyticDetailReport from '@/views/reports/analytic/AnalyticDetailReport';
import AnalyticTotalReport from '@/views/reports/analytic/AnalyticTotalReport';
import ProjectsHome from '@/views/projects/ProjectsHome';
import ProjectsAnalyticBalanceReport from '@/views/projects/analytic/ProjectsAnalyticBalanceReport';
import ProjectsAnalyticByDateReport from '@/views/projects/analytic/ProjectsAnalyticByDateReport';
import ProjectsAnalyticDetailReport from '@/views/projects/analytic/ProjectsAnalyticDetailReport';
import ProjectsAnalyticTotalReport from '@/views/projects/analytic/ProjectsAnalyticTotalReport';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/validate_access/:token',
      name: 'validateAccess',
      component: ValidateToken,
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: Error404,
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        layout: 'full'
      }
    },
    //
    // INFRAESTRUCTURA.
    //
    {
      path: '/infra',
      name: 'infraestructure',
      component: InfraHome,
      meta: {
        pageTitle: 'Infraestructura',
        breadcrumb: [
          {
            text: 'Infraestructura',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/parameters',
      name: 'parameters',
      component: Parameters,
      meta: {
        pageTitle: 'Parámetros',
        breadcrumb: [
          {
            text: 'Parámetros',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/parameter/:id',
      name: 'parameter',
      component: Parameter,
      meta: {
        pageTitle: 'Parámetro',
        breadcrumb: [
          {
            text: 'Parámetros',
            to: {name: 'parameters'}
          },
          {
            text: 'Parámetro',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/lists',
      name: 'lists',
      component: Lists,
      meta: {
        pageTitle: 'Listas',
        breadcrumb: [
          {
            text: 'Listas',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/list/:id',
      name: 'list',
      component: List,
      meta: {
        pageTitle: 'Valor de lista',
        breadcrumb: [
          {
            text: 'Listas',
            to: {name: 'lists'}
          },
          {
            text: 'Valor de lista',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/roles',
      name: 'roles',
      component: Roles,
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/role/:id',
      name: 'role',
      component: Role,
      meta: {
        pageTitle: 'Rol',
        breadcrumb: [
          {
            text: 'Roles',
            to: {name: 'roles'}
          },
          {
            text: 'Rol',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/users',
      name: 'users',
      component: Users,
      meta: {
        pageTitle: 'Usuarios',
        breadcrumb: [
          {
            text: 'Usuarios',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/user/:id',
      name: 'user',
      component: User,
      meta: {
        pageTitle: 'Usuario',
        breadcrumb: [
          {
            text: 'Usuarios',
            to: {name: 'users'}
          },
          {
            text: 'Usuario',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/branches',
      name: 'branches',
      component: Branches,
      meta: {
        pageTitle: 'Empresas',
        breadcrumb: [
          {
            text: 'Empresas',
            active: true
          }
        ]
      }
    },
    {
      path: '/infra/branch/:id',
      name: 'branch',
      component: Branch,
      meta: {
        pageTitle: 'Empresa',
        breadcrumb: [
          {
            text: 'Empresas',
            to: {name: 'branches'}
          },
          {
            text: 'Empresa',
            active: true
          }
        ]
      }
    },
    //
    // Estadísticas
    //
    {
      path: '/statistics',
      name: 'statistics',
      component: StatisticsHome,
      meta: {
        pageTitle: 'Estadísticas',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/salesman/sales',
      name: 'salesmanSaleStatistics',
      component: SalesmanSaleStatistics,
      meta: {
        pageTitle: 'Ventas por promotor',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/salesman/comparison',
      name: 'salesmanComparisonStatistics',
      component: SalesmanComparisonStatistics,
      meta: {
        pageTitle: 'Comparativo de venta por promotor',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/salesman/donation',
      name: 'salesmanDonationStatistics',
      component: SalesmanDonationStatistics,
      meta: {
        pageTitle: 'Donaciones por promotor',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/salesman/payments',
      name: 'salesmanPaymentStatistics',
      component: SalesmanPaymentStatistics,
      meta: {
        pageTitle: 'Cobros por promotor',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/salesman/product',
      name: 'salesmanSaleProduct',
      component: SalesmanProductStatistics,
      meta: {
        pageTitle: 'Venta por promotor y producto',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/channel/sales',
      name: 'channelSaleStatistics',
      component: ChannelSaleStatistics,
      meta: {
        pageTitle: 'Venta por canal',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/customer/comparison',
      name: 'customerComparisonStatistics',
      component: CustomerComparisonStatistics,
      meta: {
        pageTitle: 'Comparativo de venta por cliente',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/product/sales',
      name: 'productSaleStatistics',
      component: ProductSaleStatistics,
      meta: {
        pageTitle: 'Venta por categoría de producto',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/product/version',
      name: 'productVersionStatistics',
      component: ProductVersionStatistics,
      meta: {
        pageTitle: 'Venta por versión de producto',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    {
      path: '/statistics/product/cost_evolution',
      name: 'productCostEvolution',
      component: ProductCostEvolution,
      meta: {
        pageTitle: 'Evolución de costo',
        breadcrumb: [
          {
            text: 'Estadísticas',
            active: true
          }
        ]
      }
    },
    //
    // REPORTES
    //
    {
      path: '/reports',
      name: 'reports',
      component: ReportsHome,
      meta: {
        pageTitle: 'Reportes',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/stock/stock',
      name: 'stockReport',
      component: StockReport,
      meta: {
        pageTitle: 'Inventario',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/stock/rotation',
      name: 'stockRotation',
      component: StockRotation,
      meta: {
        pageTitle: 'Rotación',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/stock/streamline',
      name: 'streamline',
      component: Streamline,
      meta: {
        pageTitle: 'Streamline',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/stock/stock_transit',
      name: 'stockTransitReport',
      component: StockTransit,
      meta: {
        pageTitle: 'Productos en tránsito',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/sale/products',
      name: 'salesProductReport',
      component: ProductSalesReport,
      meta: {
        pageTitle: 'Producto',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/sales/products_summary',
      name: 'salesProductSummaryReport',
      component: ProductSummaryReport,
      meta: {
        pageTitle: 'Producto resumido',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/analytic/balance',
      name: 'analyticBalanceReport',
      component: AnalyticBalanceReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/analytic/balance_by_date',
      name: 'analyticByDateReport',
      component: AnalyticByDateReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico por fecha',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/analytic/balance_detail',
      name: 'analyticDetailReport',
      component: AnalyticDetailReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico detallado',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    {
      path: '/reports/analytic/balance_total',
      name: 'analyticTotalReport',
      component: AnalyticTotalReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico total',
        breadcrumb: [
          {
            text: 'Reportes',
            active: true
          }
        ]
      }
    },
    //
    // PROYECTOS
    //
    {
      path: '/projects',
      name: 'projects',
      component: ProjectsHome,
      meta: {
        pageTitle: 'Proyectos',
        breadcrumb: [
          {
            text: 'Proyectos',
            active: true
          }
        ]
      }
    },
    {
      path: '/projects/analytic/balance',
      name: 'projectsAnalyticBalance',
      component: ProjectsAnalyticBalanceReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico',
        breadcrumb: [
          {
            text: 'Proyectos',
            active: true
          }
        ]
      }
    },
    {
      path: '/projects/analytic/balance_by_date',
      name: 'projectsAnalyticByDate',
      component: ProjectsAnalyticByDateReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico por fecha',
        breadcrumb: [
          {
            text: 'Proyectos',
            active: true
          }
        ]
      }
    },
    {
      path: '/projects/analytic/balance_detail',
      name: 'projectsAnalyticDetail',
      component: ProjectsAnalyticDetailReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico detallado',
        breadcrumb: [
          {
            text: 'Proyectos',
            active: true
          }
        ]
      }
    },
    {
      path: '/projects/analytic/balance_total',
      name: 'projectsAnalyticTotal',
      component: ProjectsAnalyticTotalReport,
      meta: {
        pageTitle: 'Estado de cuenta analítico total',
        breadcrumb: [
          {
            text: 'Proyectos',
            active: true
          }
        ]
      }
    },
    //
    // CATÁLOGOS
    //
    {
      path: '/catalogs',
      name: 'catalogs',
      component: CatalogsHome,
      meta: {
        pageTitle: 'Catálogos',
        breadcrumb: [
          {
            text: 'Catálogos',
            active: true
          }
        ]
      }
    },
    {
      path: '/catalogs/customers_salesman',
      name: 'customersSalesman',
      component: CustomersSalesman,
      meta: {
        pageTitle: 'Clientes por promotor',
        breadcrumb: [
          {
            text: 'Catálogos',
            active: true
          }
        ]
      }
    },
    {
      path: '/catalogs/pricelists',
      name: 'pricelists',
      component: ProductPricelist,
      meta: {
        pageTitle: 'Listas de precios',
        breadcrumb: [
          {
            text: 'Catálogos',
            active: true
          }
        ]
      }
    },
    //
    // CONTABILIDAD
    //
    {
      path: '/accounting',
      name: 'accounting',
      component: AccountingHome,
      meta: {
        pageTitle: 'Contabilidad',
        breadcrumb: [
          {
            text: 'Contabilidad',
            active: true
          }
        ]
      }
    },
    {
      path: '/accounting/receivables_aging',
      name: 'receivablesAging',
      component: ReceivablesAging,
      meta: {
        pageTitle: 'Antigüedad de saldos',
        breadcrumb: [
          {
            text: 'Contabilidad',
            active: true
          }
        ]
      }
    },
    {
      path: '/accounting/customer_balance',
      name: 'customerBalance',
      component: CustomerBalanceByPeriod,
      meta: {
        pageTitle: 'Saldos de clientes',
        breadcrumb: [
          {
            text: 'Contabilidad',
            active: true
          }
        ]
      }
    },
    {
      path: '/accounting/ledger',
      name: 'ledger',
      component: Ledger,
      meta: {
        pageTitle: 'Diario mayor',
        breadcrumb: [
          {
            text: 'Contabilidad',
            active: true
          }
        ]
      }
    },
    {
      path: '/accounting/stock_valuation',
      name: 'stockValuation',
      component: StockValuation,
      meta: {
        pageTitle: 'Inventario valuado',
        breadcrumb: [
          {
            text: 'Contabilidad',
            active: true
          }
        ]
      }
    },
    {
      path: '/accounting/low_traffic_stock',
      name: 'lowTrafficStock',
      component: LowTrafficStock,
      meta: {
        pageTitle: 'Inventario de bajo movimiento',
        breadcrumb: [
          {
            text: 'Contabilidad',
            active: true
          }
        ]
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
});

//
// Autenticación interna
//
router.beforeEach((to, from, next) => {
  if (['login', 'validateAccess'].includes(to.name)) {
    next();
  } else if (store.state.fastway.userInfo.token) {
    if (store.state.fastway.session) {
      if (store.state.fastway.session.token == store.state.fastway.userInfo.token) {
        next();
      } else {
        next({name: 'login'});
      }
    } else {
      store.dispatch('fastway/bindSession', store.state.fastway.userInfo.token)
        .then(data => {
          let toNext = false;
          if (data) {
            toNext = (data.token == store.state.fastway.userInfo.token);
          }
          if (toNext) {
            next();
          } else {
            next({name: 'login'});
          }
        });
    }
  } else if (to.name == 'login') {
    next();
  } else {
    next({name: 'login'});
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
