import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// databaseURL: "https://informes-sbg.firebaseio.com",
const firebaseConfig = {
  apiKey: "AIzaSyC8MwMBA2BsaPykpdBIv13J_a0geac6iO8",
  authDomain: "informes-sbg.firebaseapp.com",
  projectId: "informes-sbg",
  storageBucket: "informes-sbg.appspot.com",
  messagingSenderId: "192861473563",
  appId: "1:192861473563:web:296fa7a423bbc944f4a1be",
  measurementId: "G-1TZEGFPYVZ"
};

firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();

export {
  firebase,
  db,
  auth
};
