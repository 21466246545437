import {firestoreAction} from 'vuexfire';
import {db} from '@/firebaseConfig';
import localForage from 'localforage';

export default {
  namespaced: true,
  state: {
    userInfo: {
      id: 0,
      uid: 0,
      userId: 0,
      login: '',
      userName: '',
      displayName: '',
      about: '',
      photoURL: '',
      status: '',
      userRole: '',
      branchId: 0,
      branchName: '',
      token: ''
    },
    viewParameters: {
      products: {
        pageSize: 10,
        currentPage: 1,
        order: 'Codigo',
        orderDirection: '',
        search: '',
        categoryId: 0,
        statusId: ''
      },
      partners: {
        pageSize: 10,
        currentPage: 1,
        order: 'Codigo',
        orderDirection: '',
        search: '',
        partnerType: ''
      },
      stockTransactions: {
        pageSize: 10,
        currentPage: 1,
        order: 'id',
        orderDirection: 'Descending',
        search: '',
        startDate: null,
        endDate: null,
        warehouseId: 0,
        documentTypeId: '',
        statusId: ''
      },
      saleOrders: {
        pageSize: 10,
        currentPage: 1,
        order: 'id',
        orderDirection: 'Descending',
        search: '',
        startDate: null,
        endDate: null,
        partnerId: 0,
        salesmanId: 0,
        documentTypeId: '',
        statusId: ''
      },
      saleInvoices: {
        pageSize: 10,
        currentPage: 1,
        order: 'id',
        orderDirection: 'Descending',
        search: '',
        startDate: null,
        endDate: null,
        partnerId: 0,
        salesmanId: 0,
        documentTypeId: '',
        statusId: ''
      }
    },
    modules: [],
    navMenuItems: [],
    parameters: null,
    session: null
  },
  getters: {
    getParameter: state => paramSearch => {
      const result = state.parameters.filter(parameter => parameter.id === paramSearch);
      return result.length ? result.pop() : false;
    }
  },
  mutations: {
    CLEAR_USER_INFO(state) {
      state.userInfo = {
        id: 0,
        uid: 0,
        userId: 0,
        login: '',
        userName: '',
        displayName: '',
        about: '',
        photoURL: '',
        status: '',
        userRole: '',
        branchId: 0,
        branchName: '',
        token: ''
      };
    },
    UPDATE_USER_INFO(state, payload) {
      // Get Data localStorage
      // const userInfo = JSON.parse(localStorage.getItem(`${prefix}UserInfo`)) || state.userInfo;

      const userInfo = localForage.getItem(`${state.parameters.prefix}UserInfo`) || state.userInfo;

      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          // If some of user property is null - user default property defined in state.userInfo
          state.userInfo[property] = payload[property];

          // Update key in localStorage
          userInfo[property] = payload[property];
        }
      }
      // Store data in localStorage
      localForage.setItem(`${state.parameters.prefix}UserInfo`, userInfo);
    },

    UPDATE_MODULES(state, value) {
      state.parameters = value;
    },

    UPDATE_PARAMETERS(state, value) {
      state.parameters = value;
    },

    UPDATE_SESSION(state, value) {
      state.session = {token: value};
    },

    UPDATE_NAVMENUTITEMS(state, value) {
      state.navMenuItems = value;
    },

    UPDATE_VIEWPARAMETERS(state, payload) {
      const {prefix} = state.parameters;

      // Get Data localStorage
      const viewParameters = localForage.getItem(`${prefix}ViewParameters`) || state.viewParameters;

      // eslint-disable-next-line no-restricted-syntax
      for (const property of Object.keys(payload)) {
        if (payload[property] != null) {
          state.viewParameters[property] = payload[property];

          // Update key in localStorage
          viewParameters[property] = payload[property];
        }
      }
      // Store data in localStorage
      localForage.setItem(`${prefix}ViewParameters`, (viewParameters));
    }
  },
  actions: {
    bindParameters: firestoreAction(({bindFirestoreRef}) => bindFirestoreRef('parameters', db.collection('parameters'))),

    bindSession: firestoreAction(({bindFirestoreRef}, token) => bindFirestoreRef('session', db.collection('sessions').doc(token))),

    clearUserInfo({commit}) {
      commit('CLEAR_USER_INFO');
    },

    updateUserInfo({commit}, payload) {
      commit('UPDATE_USER_INFO', payload);
    },

    updateSession({commit}, payload) {
      commit('UPDATE_SESSION', payload);
    },

    updateParameters({commit}, value) {
      commit('UPDATE_PARAMETERS', value);
    },

    updateModules({commit}, value) {
      commit('UPDATE_MODULES', value);
    },

    updateNavMenuItems({commit}, value) {
      commit('UPDATE_NAVMENUTITEMS', value);
    },

    updateViewParameters({commit}, value) {
      commit('UPDATE_VIEWPARAMETERS', value);
    }

  }
};
