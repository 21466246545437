<!-- =========================================================================================
    File Name: StockRotation.vue
    Description: Stock rotation table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 270px;">
            <ejs-datepicker ref="date" v-model="date" floatLabelType="Auto" placeholder="Calcular al día"/>
          </div>
          <div class="mr-1">
            <ejs-numerictextbox v-model="months" floatLabelType="Auto" placeholder="Número de meses" format="N0" textAlign='Right' :min="1" :max="12"/>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getData">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <ejs-grid
          id="grid"
          ref="grid"
          :dataSource="data"
          locale='es-GT'
          :toolbar='toolbarOptions'
          :allowPaging="true"
          :pageSettings='pageSettings'
          :allowSorting='true'
          :sortSettings='sortOptions'
          :allowExcelExport='true'
          :allowGrouping='false'
          :toolbarClick='toolbarClick'
          :allowFiltering='true'
          :filterSettings='filterOptions'
        >
          <e-columns>
            <e-column field='ISBN' width="200"/>
            <e-column field='Código' width="200" clipMode='EllipsisWithTooltip'/>
            <e-column field='Producto' headerText="Descripción" width="350" clipMode='EllipsisWithTooltip'/>
            <e-column field='Categoría' width="150" clipMode='EllipsisWithTooltip' :filter='checkBoxFilter'/>
            <e-column field='Editorial' width="150" :filter='checkBoxFilter'/>
            <e-column field='Casa matriz' width="150" :filter='checkBoxFilter'/>
            <e-column field='Versión' width="150" :filter='checkBoxFilter'/>
            <e-column field='Familia 1' width="150" :filter='checkBoxFilter'/>
            <e-column field='Familia 2' width="150" :filter='checkBoxFilter'/>
            <e-column field='Familia 3' width="150" :filter='checkBoxFilter'/>
            <e-column field='Meses venta' type="number" textAlign='Right' format="N0" width="130" :filter='checkBoxFilter'/>
            <e-column field='Unidades vendidas' type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='Venta sin IVA' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Costo total' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Margen' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Venta promedio' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Existencia' type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='Costo unitario' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Valor existencia' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Existencia proyectos' headerText="E. proyectos" type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='transit1' headerText="Tr. lote 1" type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='transit2' headerText="Tr. lote 2" type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='transit3' headerText="Tr. lote 3" type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='transit4' headerText="Tr. lote 4" type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='transit5' headerText="Tr. L. directo" type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='transitO' headerText="Tr. Otros" type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='Unidades proyectadas' type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
            <e-column field='Valor proyectadas' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Meses inventario' type="number" textAlign='Right' format="N2" width="130" :allowFiltering=false />
            <e-column field='Días inventario' type="number" textAlign='Right' format="N0" width="130" :allowFiltering=false />
          </e-columns>
        </ejs-grid>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import {NumericTextBoxPlugin} from "@syncfusion/ej2-vue-inputs";
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

Vue.use(GridPlugin, AutoComplete);
Vue.use(DatePickerPlugin);
Vue.use(NumericTextBoxPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datepicker: {
      placeholder: 'Seleccione fecha a calcular',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      today: 'Hoy'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      date: new Date(),
      months: 12,
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 25},
      sortOptions: {
        columns: [{
          field: 'Producto',
          direction: 'Ascending'
        }, {
          field: 'ISBN',
          direction: 'Ascending'
        }]
      },
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Group, Filter],
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  methods: {
    formatDate (field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    toolbarClick (args) {
      if (args.item.id === 'grid_excelexport') {
        this.$refs.grid.excelExport({fileName: `Rotación de inventario al ${this.date}.xlsx`});
      }
    },
    getData() {
      if (this.date) {
        const loading = this.$loading.show();
        this.data = [];
        const self = this;
        this.$http.post(`${this.api}/v1/statistics/stock_rotation`, {
          endDate: this.dateISOFormat(this.date),
          months: this.months
        })
          .then(response => {
            if (response.data.success) {
              if (response.data.data) {
                self.data = response.data.data;
              }
              self.gridReady = true;
              loading.hide();
            }
          })
          .catch(error => {
            let errorMessage = '';
            if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad'
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar reporte de ventas por promotor',
                text: errorMessage,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
            loading.hide();
          });
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
