<!-- =========================================================================================
    File Name: Streamline.vue
    Description: Streamline upload data
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 270px;">
            <ejs-datepicker ref="date" v-model="date" floatLabelType="Auto" placeholder="Calcular al día"/>
          </div>
          <div class="mr-1">
            <ejs-numerictextbox v-model="months" floatLabelType="Auto" placeholder="Número de meses" format="N0" textAlign='Right' :min="1" :max="120"/>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getData">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
          <div class="ml-1 mt-1">
            <b-button variant="primary" class="btn-icon rounded-circle" @click="getFile">
              <feather-icon icon="DownloadIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <ejs-grid
          id="grid"
          ref="grid"
          :dataSource="data"
          :dataBound='dataBound'
          locale='es-GT'
          :toolbar='toolbarOptions'
          :allowPaging="true"
          :pageSettings='pageSettings'
          :allowSorting='true'
          :allowExcelExport='true'
          :toolbarClick='toolbarClick'
          :allowFiltering='true'
          :filterSettings='filterOptions'
        >
        </ejs-grid>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import {NumericTextBoxPlugin} from "@syncfusion/ej2-vue-inputs";
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
// To load the culture based first day of week

Vue.use(GridPlugin, AutoComplete);
Vue.use(DatePickerPlugin);
Vue.use(NumericTextBoxPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datepicker: {
      placeholder: 'Seleccione fecha a calcular',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      today: 'Hoy'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      date: new Date(),
      months: 60,
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 25},
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Group, Filter],
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  methods: {
    formatDate (field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    formatPrintDate(d) {
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}-${(d.getMonth() + 1).toString()
        .padStart(2, '0')}-${d.getFullYear()}`;
    },
    toolbarClick (args) {
      if (args.item.id === 'grid_excelexport') {
        this.$refs.grid.excelExport({fileName: `CargaStreamline-${this.formatPrintDate(this.date)}.xlsx`});
      }
    },
    dataBound () {
      for (let i = 0; i < this.$refs.grid.ej2Instances.columns.length; i++) {
        if (i == 0) {
          this.$refs.grid.ej2Instances.columns[i].width = 70;
          this.$refs.grid.ej2Instances.columns[i].allowFiltering = false;
        } else if (i == 1) {
          this.$refs.grid.ej2Instances.columns[i].width = 100;
        } else if (i == 2) {
          this.$refs.grid.ej2Instances.columns[i].width = 200;
          this.$refs.grid.ej2Instances.columns[i].allowFiltering = false;
        } else if (i == 3) {
          this.$refs.grid.ej2Instances.columns[i].width = 350;
          this.$refs.grid.ej2Instances.columns[i].allowFiltering = false;
        } else if (i == 4 || i == 15) {
          this.$refs.grid.ej2Instances.columns[i].width = 150;
          this.$refs.grid.ej2Instances.columns[i].allowFiltering = false;
        } else {
          this.$refs.grid.ej2Instances.columns[i].width = 130;
        }
        if (i == 3 || i == 4) {
          this.$refs.grid.ej2Instances.columns[i].clipMode = 'EllipsisWithTooltip';
        }
        if (i == 1 || i == 4) {
          this.$refs.grid.ej2Instances.columns[i].filter = 'checkBoxFilter';
        }
        if (i >= 12 && i <= 14) {
          this.$refs.grid.ej2Instances.columns[i].format = 'N2';
        }
        if (i > 15) {
          this.$refs.grid.ej2Instances.columns[i].format = 'N0';
        }
        if (i >= 12 && i <= 14) {
          this.$refs.grid.ej2Instances.columns[i].allowFiltering = false;
        }
        if (i > 15) {
          this.$refs.grid.ej2Instances.columns[i].allowFiltering = false;
          this.$refs.grid.ej2Instances.columns[i].hidden = true;
        }
      }
      this.$refs.grid.ej2Instances.refreshColumns();
    },
    getData() {
      if (this.date) {
        const loading = this.$loading.show();
        this.data = [];
        const self = this;
        this.$http.post(`${this.api}/v1/reports/streamline`, {
          endDate: this.dateISOFormat(this.date),
          months: this.months
        })
          .then(response => {
            if (response.data.success) {
              if (response.data.data) {
                self.data = [];
                let values = '';
                for (let i = 0; i < response.data.data.length; i++) {
                  values = JSON.parse(response.data.data[i].Quantities);
                  delete response.data.data[i].Quantities;
                  self.data.push({...response.data.data[i], ...values});
                }
              }
              self.gridReady = true;
              loading.hide();
            }
          })
          .catch(error => {
            let errorMessage = '';
            if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad'
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar reporte de ventas por promotor',
                text: errorMessage,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
            loading.hide();
          });
      }
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    getFile() {
      if (this.date) {
        const loading = this.$loading.show();
        this.data = [];
        const self = this;
        this.$http.post(`${this.api}/v1/reports/streamline_file`, {
          endDate: this.dateISOFormat(this.date),
          months: this.months
        })
          .then(response => {
            if (response.data.success) {
              if (response.data.data) {
                const d1 = `${this.date.getDate().toString().padStart(2, "0")}-${(this.date.getMonth() + 1).toString().padStart(2, "0")}-${this.date.getFullYear()}`;
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(self.b64toBlob(response.data.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
                link.download = `Streamline - ${d1} - ${self.months} months.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
              loading.hide();
            }
          })
          .catch(error => {
            let errorMessage = '';
            if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad'
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al genrear archivo de Streamline',
                text: errorMessage,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
            loading.hide();
          });
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
