<!-- =========================================================================================
  File Name: Ledger.vue
  Description: kardex report
  ----------------------------------------------------------------------------------------
  Item Name: C4 - User list for management
  Author: Oscar Ramírez O.
  Author URL: https://acentoNET.com
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 135px;">
            <ejs-dropdownlist
              ref="year"
              v-model="year"
              floatLabelType="Auto"
              placeholder="Seleccione ejercicio"
              highlight=true
              :dataSource="years"
              :fields="{text: 'year', value: 'year'}"
              :change="onYearChange"
            />
          </div>
          <div class="mr-1">
            <ejs-dropdownlist
              ref="account"
              v-model="accountCode"
              floatLabelType="Auto"
              placeholder="Seleccione cuenta"
              highlight=true
              :dataSource="accounts"
              :fields="{text: 'searchName', value: 'code'}"
              :query='accountQuery'
              allowFiltering=true
              :filtering='accountFiltering'
            />
          </div>
          <div class="mr-1" style="min-width: 495px;">
            <ejs-daterangepicker ref="period" :startDate="startDate" :endDate="endDate" floatLabelType="Auto" placeholder="Seleccione período"/>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getReport">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <ejs-grid
          ref="grid"
          :dataSource="data"
          locale='es-GT'
          :toolbar='toolbarOptions'
          :allowPaging="true"
          :pageSettings='pageSettings'
          :allowSorting='true'
          :sortSettings='sortOptions'
          :allowExcelExport='true'
          :toolbarClick='toolbarClick'
        >
          <e-columns>
            <e-column field="id" :visible=false headerText="ID" :isPrimaryKey="true"/>
            <e-column field='date' headerText='Fecha' width="130" :valueAccessor="formatDate"/>
            <e-column field="journal" headerText="Diario" width="200"/>
            <e-column field="reference" headerText="Referencia" width="200"/>
            <e-column field="debit" type="number" headerText="Debe" textAlign='Right' :valueAccessor="formatValue" width="120"/>
            <e-column field="credit" type="number" headerText="Haber" textAlign='Right' :valueAccessor="formatValue" width="120"/>
            <e-column field="balance" type="number" headerText="Saldo" textAlign='Right' format="N2" width="120"/>
            <e-column field="analyticAccount" headerText="Cuenta analítica" width="250"/>
            <e-column field="partner" headerText="Persona" width="250"/>
            <e-column field="productCode" headerText="Código producto" width="250"/>
            <e-column field="isbn" headerText="ISBN" width="250"/>
            <e-column field="productName" headerText="Nombre producto" width="350"/>
            <e-column field="quantity" type="number" headerText="Cantidad" textAlign='Right' format="N0" width="120"/>
          </e-columns>
          <e-aggregates>
            <e-aggregate>
              <e-columns>
                <e-column type="Sum" field="debit" format="N2"/>
                <e-column type="Sum" field="credit" format="N2"/>
              </e-columns>
            </e-aggregate>
          </e-aggregates>
        </ejs-grid>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {Query} from "@syncfusion/ej2-data";
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Aggregate} from '@syncfusion/ej2-vue-grids';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {DateRangePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

Vue.use(DropDownListPlugin);
Vue.use(DateRangePickerPlugin);
Vue.use(GridPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    daterangepicker: {
      placeholder: 'Seleccione período',
      startLabel: 'Fecha inicial',
      endLabel: 'Fecha final',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      selectedDays: 'Seleccione día inicial y final',
      days: 'Días'
    },
    grid: {
      EmptyRecord: 'No se encontró información relacionada',
      Search: 'Buscar',
      Excelexport: 'Exportar'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      loaded: false,
      gridReady: false,
      year: null,
      startDate: null,
      endDate: null,
      years: [],
      accounts: [],
      accountCode: '',
      accountQuery: new Query().select(['searchName', 'code'])
        .take(20),
      data: [],
      editID: null,
      addNewDataSidebar: false,
      pageSettings: {pageSize: 15},
      toolbarOptions: ['Search', 'ExcelExport'],
      sortOptions: {
        columns: [{
          field: 'date',
          direction: 'Ascending'
        }, {
          field: 'reference',
          direction: 'Ascending'
        }, {
          field: 'id',
          direction: 'Ascending'
        }]
      },
      cardTitle: 'Diario mayor'
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Aggregate]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLMS;
    }
  },
  mounted() {
    const loading = this.$loading.show();
    const self = this;
    this.$http
      .post(`${this.api}/accounting/accounts/fiscal_years`)
      .then(response => {
        if (response.data.success) {
          self.years = response.data.data;
          loading.hide();
        } else {
          loading.hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar ejercicios fiscales',
              text: response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
        }
      });
  },
  methods: {
    accountFiltering (e) {
      const searchData = JSON.parse(JSON.stringify(this.accounts));
      if (e.text == '') {
        e.updateData(searchData);
      } else {
        // var query = new Query().select(['name', 'id']);
        let query = new Query().select(['searchName', 'code']);
        query = (e.text !== '') ? query.where('searchName', 'contains', e.text, true, true) : query;
        e.updateData(searchData, query);
      }
    },
    dateFormat(value) {
      const d = new Date(value);
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()} ${d.getHours()
        .toString()
        .padStart(2, '0')}:${d.getMinutes()
        .toString()
        .padStart(2, '0')}:${d.getSeconds()
        .toString()
        .padStart(2, '0')}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    formatDate (field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    formatValue(field, data) {
      return (data[field] == 0 ? '' : this.numericFormat(data[field], 2));
    },
    numericFormat(value, decimals) {
      let ret = value;
      if (typeof value !== 'undefined') {
        ret = value.toFixed(decimals)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
      return ret;
    },
    discountFormatter(field, data) {
      return (data[field] == 0 ? '' : data[field].toFixed(2));
    },
    toolbarClick (args) {
      if (args.item.id.includes('excelexport')) { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        const excelExportProperties = {
          fileName: "Diario Mayor.xlsx"
        };
        this.$refs.grid.excelExport(excelExportProperties);
      }
    },
    onYearChange(year) {
      const loading = this.$loading.show();
      this.startDate = new Date(year.value, 0, 1, 0, 0, 0, 0);
      this.endDate = new Date(year.value, 11, 31, 23, 59, 59, 999);
      this.accounts = [];
      const self = this;
      this.$http.post(`${this.api}/accounting/accounts/list`, {year: year.value})
        .then(response => {
          if (response.data.success) {
            if (response.data.data) {
              self.accounts = response.data.data
            }
            loading.hide();
          } else {
            loading.hide();
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar cuentas contables',
                text: response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          }
          self.loaded = true;
        })
        .catch(error => {
          let errorMessage = '';
          if (error.message) {
            errorMessage = error.message;
          } else if (error.response) {
            errorMessage = error.response.data.ErrorMessage
          } else {
            errorMessage = 'Error de conectividad'
          }
          self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar lista de cuentas contables',
              text: errorMessage,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
          loading.hide();
        });
    },
    getReport() {
      this.startDate = this.$refs.period.getSelectedRange().startDate;
      this.endDate = this.$refs.period.getSelectedRange().endDate;
      if (this.startDate) {
        if (this.endDate) {
          this.startDate.setHours(0);
          this.startDate.setMinutes(0);
          this.startDate.setSeconds(0);
          this.endDate.setHours(23);
          this.endDate.setMinutes(59);
          this.endDate.setSeconds(59);
          if (this.startDate < this.endDate) {
            const loading = this.$loading.show();
            const self = this;
            const data = {
              year: this.year,
              account: this.accountCode,
              startDate: this.dateISOFormat(this.startDate),
              endDate: this.dateISOFormat(this.endDate)
            };
            this.$http.post(`${this.api}/accounting/ledger/account`, data)
              .then(response => {
                if (response.data.success) {
                  if (response.data.data) {
                    self.data = response.data.data
                  } else {
                    self.data = [];
                  }
                  self.gridReady = true;
                  loading.hide();
                } else {
                  loading.hide();
                  self.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error al cargar diario mayor',
                      text: response.data.message,
                      icon: 'AlertCircleIcon',
                      variant: 'danger'
                    }
                  }, {timeout});
                }
                self.loaded = true;
              })
              .catch(error => {
                self.loaded = true;
                let errorMessage = '';
                if (error.message) {
                  errorMessage = error.message;
                } else if (error.response) {
                  errorMessage = error.response.data.ErrorMessage;
                } else {
                  errorMessage = 'Error de conectividad';
                }
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al cargar diario mayor',
                    text: errorMessage,
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                }, {timeout});
                loading.hide();
              });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error en parámetros',
                text: 'Fecha final debe ser mayor a fecha inicial',
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error en parámetros',
              text: 'Debe especificar una fecha de finalización',
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error en parámetros',
            text: 'Debe especificar una fecha de inicio',
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      }
    },
    refreshData() {
      this.getReport();
    }
  }
}
</script>

<style lang="scss">
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
