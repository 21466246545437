<!-- =========================================================================================
    File Name: ReceivablesAging.vue
    Description: Pending customers' invoices aging
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1" style="min-width: 270px;">
            <ejs-datepicker ref="date" v-model="date" floatLabelType="Auto" placeholder="Calcular al día"/>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getData">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <ejs-tab ref="tabs">
          <div class="e-tab-header">
            <div>Tabla</div>
            <div>Lista</div>
          </div>
          <div class="e-content">
            <div>
              <b-button variant="flat-success" @click="excelExport">Exportar a Excel</b-button>
              <ejs-pivotview
                ref="pivot"
                :dataSourceSettings="dataSourceSettings"
                showFieldList=true
                locale='es-GT'
                allowExcelExport=true
                height="1000"
              />
            </div>
            <div>
              <ejs-grid
                id="grid"
                ref="grid"
                :dataSource="data"
                locale='es-GT'
                :toolbar='toolbarOptions'
                :allowPaging="true"
                :pageSettings='pageSettings'
                :allowSorting='true'
                :sortSettings='sortOptions'
                :allowExcelExport='true'
                :allowGrouping='true'
                :toolbarClick='toolbarClick'
              >
                <e-columns>
                  <e-column field='salesman' headerText='Promotor' width="200" clipMode='EllipsisWithTooltip'/>
                  <e-column field='partner' width="300" headerText='Cliente' clipMode='EllipsisWithTooltip'/>
                  <e-column field='number' headerText='# Documento' width="170" clipMode='EllipsisWithTooltip'/>
                  <e-column field='date' headerText='Fecha' width="110" :valueAccessor="formatDate"/>
                  <e-column field='dateDue' headerText='Vencimiento' width="130" :valueAccessor="formatDate"/>
                  <e-column field='amount' headerText='Monto' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='amountPaid' headerText='Pagado' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='days' headerText='Días' type="number" textAlign='Right' format="N0" width="100"/>
                  <e-column field='notDue' headerText='No vencido' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='at30' headerText='A 30 días' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='at45' headerText='A 45 días' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='at60' headerText='A 60 días' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='at90' headerText='A 90 días' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='at120' headerText='A 120 días' type="number" textAlign='Right' format="N2" width="120"/>
                  <e-column field='more120' headerText='Más de 120 días' type="number" textAlign='Right' format="N2" width="140"/>
                </e-columns>
              </ejs-grid>
            </div>
          </div>
        </ejs-tab>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {DatePickerPlugin} from '@syncfusion/ej2-vue-calendars';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';// To load the culture based first day of week
import {PivotViewPlugin, GroupingBar, FieldList} from '@syncfusion/ej2-vue-pivotview';
import {TabPlugin} from '@syncfusion/ej2-vue-navigations';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

Vue.use(GridPlugin, AutoComplete);
Vue.use(DropDownListPlugin);
Vue.use(DatePickerPlugin);
Vue.use(PivotViewPlugin);
Vue.use(TabPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    datepicker: {
      placeholder: 'Seleccione fecha a calcular',
      applyText: 'Seleccionar',
      cancelText: 'Cancelar',
      today: 'Hoy'
    },
    pivotview: {
      grandTotal: 'Gran total',
      total: 'Total',
      value: 'Valor',
      noValue: 'Sin valor',
      row: 'Fila',
      column: 'Columna',
      collapse: 'Colapsar',
      expand: 'Expandir',
      rowAxisPrompt: 'Arrastre fila aquí',
      columnAxisPrompt: 'Arrastre columna aquí',
      valueAxisPrompt: 'Arrastre valor aquí',
      filterAxisPrompt: 'Arrastre filtro aquí',
      filter: 'Filtro',
      filtered: 'Filtrado',
      sort: 'Ordenar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      calculatedField: 'Campo calculado',
      sum: 'Suma'
    },
    pivotfieldlist: {
      fieldList: 'Lista de campos',
      dropRowPrompt: 'Arrastre fila aquí',
      dropColPrompt: 'Arrastre columna aquí',
      dropValPrompt: 'Arrastre valor aquí',
      dropFilterPrompt: 'Arrastre fo;trp aquí',
      addPrompt: 'Agregar campo',
      centerHeader: 'Centrar encabezado:',
      add: 'Agregar',
      drag: 'Arrastrar',
      filters: 'Filtros',
      rows: 'Filas',
      columns: 'Columnas',
      values: 'Valores',
      error: 'Error',
      dropAction: 'El campo calculado no puede estar en otra región excepto el valor en eje.',
      search: 'Buscar',
      close: 'Cerrar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      alert: 'Alerta',
      warning: 'Advertencia',
      ok: 'Aceptar',
      allFields: 'Todos los campos',
      noMatches: 'Sin coincidencias'
    },
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer,
    BButton
  },
  data() {
    return {
      dataSourceSettings: {
        dataSource: this.data,
        expandAll: false,
        values: [
          {
            name: 'amount',
            caption: 'Monto'
          },
          {
            name: 'amountDue',
            caption: 'Saldo'
          },
          {
            name: 'notDue',
            caption: 'No vencido'
          },
          {
            name: 'at30',
            caption: 'A 30 días'
          },
          {
            name: 'at45',
            caption: 'A 45 días'
          },
          {
            name: 'at60',
            caption: 'A 60 días'
          },
          {
            name: 'at90',
            caption: 'A 90 días'
          },
          {
            name: 'at120',
            caption: 'A 120 días'
          },
          {
            name: 'more120',
            caption: 'Más de 120 días'
          }
        ],
        rows: [
          {
            name: 'salesman',
            caption: 'Promotor'
          },
          {
            name: 'partner',
            caption: 'Cliente'
          },
          {
            name: 'number',
            caption: 'Documento'
          }
        ],
        formatSettings: [
          {
            name: 'amount',
            format: 'N2'
          },
          {
            name: 'amountPaid',
            format: 'N2'
          },
          {
            name: 'amountDue',
            format: 'N2'
          },
          {
            name: 'notDue',
            format: 'N2'
          },
          {
            name: 'at30',
            format: 'N2'
          },
          {
            name: 'at45',
            format: 'N2'
          },
          {
            name: 'at60',
            format: 'N2'
          },
          {
            name: 'at90',
            format: 'N2'
          },
          {
            name: 'at120',
            format: 'N2'
          },
          {
            name: 'more120',
            format: 'N2'
          }
        ],
        filters: []
      },
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      date: new Date(),
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 25},
      sortOptions: {
        columns: [
          {
            field: 'salesman',
            direction: 'Ascending'
          },
          {
            field: 'partner',
            direction: 'Ascending'
          },
          {
            field: 'dateDue',
            direction: 'Ascending'
          },
          {
            field: 'number',
            direction: 'Ascending'
          }
        ]
      },
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Group, Filter],
    pivotview: [GroupingBar, FieldList]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  methods: {
    formatDate (field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    toolbarClick (args) {
      if (args.item.id === 'grid_excelexport') {
        this.$refs.grid.excelExport({fileName: `Antigüedad de saldos al ${this.date}.xlsx`});
      }
    },
    excelExport() {
      const pivot = this.$refs.pivot.ej2Instances;
      pivot.excelExport();
    },
    getData() {
      if (this.date) {
        const loading = this.$loading.show();
        this.data = [];
        const self = this;
        this.$http.post(`${this.api}/v1/odoo/receivable_aging`, {date: this.dateISOFormat(this.date)})
          .then(response => {
            if (response.data.success) {
              if (response.data.data) {
                self.data = response.data.data;
              }
              self.$refs.pivot.ej2Instances.dataSourceSettings.dataSource = self.data;
              self.gridReady = true;
              loading.hide();
            }
          })
          .catch(error => {
            let errorMessage = '';
            if (error.message) {
              errorMessage = error.message;
            } else if (error.response) {
              errorMessage = error.response.data.ErrorMessage;
            } else {
              errorMessage = 'Error de conectividad'
            }
            self.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error al cargar reporte de ventas por promotor',
                text: errorMessage,
                icon: 'AlertCircleIcon',
                variant: 'danger'
              }
            }, {timeout});
            loading.hide();
          });
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
