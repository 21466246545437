<template>
  <div>
    <b-card :title="userInfo.userName" class="card-profile w-50 mx-auto mt-5">
      <div class="profile-image-wrapper">
        <div class="profile-image p-0">
          <b-avatar
            size="114"
            variant="light"
            :src="require('@/assets/images/portrait/small/avatar-s-9.jpg')"
          />
        </div>
      </div>
      <h2>{{ userInfo.displayName }}</h2>
      <h4 class="text-muted">
        {{ userInfo.branchName }}
      </h4>
      <hr class="mb-2">

      <!-- follower projects rank -->
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h5 class="text-muted font-weight-bolder">
            Archivos cargados
          </h5>
          <h4 class="mb-0">
            1
          </h4>
        </div>
        <div>
          <h5 class="text-muted font-weight-bolder">
            Último acceso
          </h5>
          <h4 class="mb-0">
            01/01/2021
          </h4>
        </div>
        <div>
          <h5 class="text-muted font-weight-bolder">
            ID
          </h5>
          <h4 class="mb-0">
            {{ userInfo.userId }}
          </h4>
        </div>
      </div>

      <hr class="my-2">

      <b-button to="/" variant="primary" size="lg">Inicio</b-button>

    </b-card>
  </div>
</template>

<script>
import {BCard, BAvatar, BButton} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BAvatar,
    BButton
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURL;
    },
    userInfo() {
      return this.$store.state.fastway.userInfo;
    }
  },
};
</script>

<style>

</style>
