<!-- =========================================================================================
    File Name: ProductCostEvolution.vue
    Description: Evolution of cost by product
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card class="mb-1">
      <b-container>
        <div class="d-flex justify-start">
          <div class="mr-1">
            <ejs-numerictextbox v-model="startYear" format="0000" min=2015 :max="currentYear" floatLabelType="Auto" placeholder="Año inicial"/>
          </div>
          <div class="mr-1">
            <ejs-numerictextbox v-model="endYear" format="0000" min=2015 :max="currentYear" floatLabelType="Auto" placeholder="Año final"/>
          </div>
          <div class="mr-1 w-100">
            <product-search v-model="productCode" :enabled="true" @change="onProductChange"/>
          </div>
          <div class="mt-1">
            <b-button variant="success" class="btn-icon rounded-circle" @click="getChart">
              <feather-icon icon="SearchIcon"/>
            </b-button>
          </div>
        </div>
      </b-container>
    </b-card>
    <b-card v-show="gridReady">
      <b-container>
        <vue-apex-charts type="line" height="600" :options="chart1.chartOptions" :series="chart1.series"></vue-apex-charts>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer, BButton} from 'bootstrap-vue';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {NumericTextBoxPlugin} from '@syncfusion/ej2-vue-inputs';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import VueApexCharts from 'vue-apexcharts';

Vue.use(DropDownListPlugin);
Vue.use(NumericTextBoxPlugin);

const timeout = Vue.$timeout1;
const month = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export default {
  components: {
    BCard,
    BContainer,
    BButton,
    ProductSearch: () => import('@/views/components/ProductSearch'),
    VueApexCharts
  },
  data() {
    return {
      chart1: {
        series: [],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [3, 1]
          }
        }
      },
      mounted: false,
      gridReady: false,
      productCode: '',
      startYear: 2015,
      endYear: (new Date()).getFullYear(),
      dateFormatOptions: {
        type: 'dateTime',
        format: 'dd/MM/yyyy hh:mm a'
      },
      data: []
    }
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    },
    currentYear() {
      const d = new Date();
      return d.getFullYear();
    }
  },
  methods: {
    formatDate (field, data) {
      const d = new Date(data[field].replace('Z', ''));
      return `${d.getDate()
        .toString()
        .padStart(2, '0')}/${(d.getMonth() + 1).toString()
        .padStart(2, '0')}/${d.getFullYear()}`;
    },
    dateISOFormat(value) {
      const d = new Date(value.getTime() - (6 * 60 * 60 * 1000));
      return d.toISOString();
    },
    onProductChange(args) {
      this.productCode = '';
      if (args.itemData) {
        this.productCode = args.itemData.code;
      }
    },
    getChart() {
      this.chart1.series = [];
      this.gridReady = false;
      if (this.startYear) {
        if (this.endYear) {
          if (this.startYear <= this.endYear) {
            const loading = this.$loading.show();
            const self = this;
            this.$http.post(`${this.api}/v1/statistics/product_cost_evolution`, {
              startYear: this.startYear,
              endYear: this.endYear,
              code: this.productCode
            })
              .then(response => {
                if (response.data.success) {
                  if (response.data.data) {
                    let xlabel = '';
                    const data1 = [];
                    const data2 = [];
                    const data3 = [];
                    const data4 = [];
                    for (let i = 0; i < response.data.data.length; i++) {
                      xlabel = `${response.data.data[i].year}/s${response.data.data[i].week}-${month[response.data.data[i].month]}`;
                      data1.push({ x: xlabel, y: response.data.data[i].cost });
                      data2.push({ x: xlabel, y: response.data.data[i].margin });
                      data3.push({ x: xlabel, y: response.data.data[i].maxPrice });
                      data4.push({ x: xlabel, y: response.data.data[i].minPrice });
                      // data3.push({ x: xlabel, y: [response.data.data[i].avgPrice, response.data.data[i].maxPrice, response.data.data[i].minPrice, response.data.data[i].avgPrice] });
                    }
                    self.chart1.series.push({name: 'Costo', type: 'line', data: data1});
                    self.chart1.series.push({name: 'Márgen', type: 'line', data: data2});
                    self.chart1.series.push({name: 'Precio máximo', type: 'line', data: data3});
                    self.chart1.series.push({name: 'Precio mínimo', type: 'line', data: data4});
                    // self.chart1.series.push({name: 'Precios', type: 'candlestick', data: data3});
                  }
                  loading.hide();
                  self.gridReady = true;
                }
              })
              .catch(error => {
                let errorMessage = '';
                if (error.message) {
                  errorMessage = error.message;
                } else if (error.response) {
                  errorMessage = error.response.data.ErrorMessage;
                } else {
                  errorMessage = 'Error de conectividad'
                }
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error al cargar reporte de evolución de costo por producto',
                    text: errorMessage,
                    icon: 'AlertCircleIcon',
                    variant: 'danger'
                  }
                }, {timeout});
                loading.hide();
              });
          }
        }
      }
    },
    refreshData(card) {
      this.getChart();
      if (typeof card !== 'undefined') {
        card.removeRefreshAnimation(500);
      }
    }
  }
}

</script>

<style lang="scss">
@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
