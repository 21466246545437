<!-- =========================================================================================
    File Name: ProductPricelist.vue
    Description: Products' list with prices
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card v-show="gridReady">
      <b-container>
        <div>
          <ejs-grid
            id="grid"
            ref="grid"
            :dataSource="data"
            locale='es-GT'
            :toolbar='toolbarOptions'
            :allowPaging="true"
            :pageSettings='pageSettings'
            :allowSorting='true'
            :allowExcelExport='true'
            :toolbarClick='toolbarClick'
            :allowFiltering='true'
            :filterSettings='filterOptions'
          >
          </ejs-grid>
        </div>
      </b-container>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue';
import {BCard, BContainer} from 'bootstrap-vue';
import {loadCldr, L10n, setCulture} from '@syncfusion/ej2-base';
import {GridPlugin, Toolbar, Search, Page, Sort, ExcelExport, Group, Filter} from '@syncfusion/ej2-vue-grids';
import {AutoComplete} from '@syncfusion/ej2-dropdowns';
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import gregorian from 'cldr-data/main/es-GT/ca-gregorian.json';
import numbers from 'cldr-data/main/es-GT/numbers.json';
import timeZoneNames from 'cldr-data/main/es-GT/timeZoneNames.json';
import weekData from 'cldr-data/supplemental/weekData.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
// To load the culture based first day of week

Vue.use(GridPlugin, AutoComplete);
Vue.use(DropDownListPlugin);

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
setCulture('es-GT');
L10n.load({
  'es-GT': {
    grid: {
      EmptyRecord: 'No se encontraron datos',
      Search: 'Buscar',
      Excelexport: 'Exportar',
      GroupDropArea: 'Arrastre un encabezado de columna aquí para agrupar por esa columna'
    },
    pager: {
      currentPageInfo: '{0} de {1} páginas',
      firstPageTooltip: 'Ir a primera página',
      lastPageTooltip: 'Ir a última página',
      nextPageTooltip: 'Ir a página siguiente',
      previousPageTooltip: 'Ir a página anterior'
    }
  }
});

const timeout = Vue.$timeout1;

export default {
  components: {
    BCard,
    BContainer
  },
  data() {
    return {
      mounted: false,
      gridReady: false,
      allowFiltering: true,
      startDate: null,
      endDate: null,
      dateFormatOptions: {type: 'dateTime', format: 'dd/MM/yyyy hh:mm a'},
      cardTitle: 'Listas de precios',
      toolbarOptions: ['Search', 'ExcelExport'],
      pageSettings: {pageSize: 25},
      data: [],
      filterOptions: {
        type: 'Menu'
      },
      checkBoxFilter: {
        type: 'CheckBox'
      }
    }
  },
  provide: {
    grid: [Toolbar, Search, Page, Sort, ExcelExport, Group, Filter]
  },
  computed: {
    config() {
      return this.$store.state.fastway.parameters;
    },
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  mounted() {
    this.getReport();
  },
  methods: {
    toolbarClick (args) {
      if (args.item.id === 'grid_excelexport') {
        this.$refs.grid.excelExport({fileName: `Listas de precios.xlsx`});
      }
    },
    excelExport() {
      const pivot = this.$refs.pivot.ej2Instances;
      pivot.excelExport();
    },
    getReport() {
      const loading = this.$loading.show();
      const self = this;
      this.$http.post(`${this.api}/v1/odoo/pricelist`, {}).then(response => {
        if (response.data.success) {
          self.gridReady = true;
          if (response.data.data) {
            self.data = response.data.data;
          }
          loading.hide();
        }
      }).catch(error => {
        let errorMessage = '';
        if (error.message) {
          errorMessage = error.message;
        } else if (error.response) {
          errorMessage = error.response.data.ErrorMessage;
        } else {
          errorMessage = 'Error de conectividad'
        }
        self.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cargar reporte de ventas por promotor',
            text: errorMessage,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
        loading.hide();
      });
    }
  }

}

</script>

<style lang="scss">
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-pivotview/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
</style>
